var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid p-0 outer-layout"},[_c('div',{staticClass:"logo-container"},[_c('notifications',{attrs:{"group":"dashboard","duration":2000,"width":"100%"}}),_c('img',{staticClass:"fnf-google-logo",attrs:{"src":require("../assets/fnf.png"),"alt":"Vue"}})],1),_c('div',{staticClass:"row mx-0 justify-content-center register-container align-items-center container-min-height pt-5per pb-5per"},[_c('div',{staticClass:"registerCard"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-xl-12 p-20"},[_c('div',{staticClass:"registerHeader pb-1"},[_vm._v(" "+_vm._s(_vm.labels.WELCOME_TO_FLUTE_N_FEATHER)+" ")]),_c('div',{staticClass:"registerSecondaryHeader pb-2"},[_vm._v(" "+_vm._s(_vm.labels.YOUR_CHILDS_PERSONAL_COMPANION)+" ")]),_c('form',[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.labels.CHILDS_OR_TEACHERS_NAME)),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('text-field',{attrs:{"id":"fullName","name":"fullName","placeholder":"Enter your child's name","valid":!_vm.$v.form_data.fullName.$error,"error-message":_vm.isSubmitted && !_vm.$v.form_data.fullName.required
                  ? 'Full Name is required'
                  : !_vm.$v.form_data.fullName.regex
                    ? 'Only alphabetic characters and spaces are allowed'
                    : ''},model:{value:(_vm.form_data.fullName),callback:function ($$v) {_vm.$set(_vm.form_data, "fullName", $$v)},expression:"form_data.fullName"}})],1),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-xl-12 p-0 form-group"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.labels.CHILDS_AGE)),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('v-select',{staticClass:"custom-v-select",class:{ 'is-invalid': !_vm.$v.form_data.age.$error },attrs:{"placeholder":"Choose","searchable":false,"clearable":false,"options":_vm.ageArr},model:{value:(_vm.form_data.age),callback:function ($$v) {_vm.$set(_vm.form_data, "age", $$v)},expression:"form_data.age"}}),(_vm.isSubmitted && !_vm.$v.form_data.age.required)?_c('span',{staticClass:"error"},[_vm._v("Age is required")]):_vm._e()],1),_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-xl-12 p-0"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.labels.CHILDS_GENDER))]),_c('v-select',{staticClass:"custom-v-select",attrs:{"placeholder":"Choose","searchable":false,"clearable":false,"options":_vm.genderArr},model:{value:(_vm.form_data.gender),callback:function ($$v) {_vm.$set(_vm.form_data, "gender", $$v)},expression:"form_data.gender"}})],1)])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.labels.EMAIL)),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('text-field',{attrs:{"id":"email","name":"email","placeholder":"Enter your email address","valid":!_vm.$v.form_data.email.$error,"read-only":true,"error-message":_vm.isSubmitted && !_vm.$v.form_data.email.required
                  ? 'Email is required'
                  : !_vm.$v.form_data.email.email
                    ? 'Please enter a valid email address'
                    : ''},model:{value:(_vm.form_data.email),callback:function ($$v) {_vm.$set(_vm.form_data, "email", $$v)},expression:"form_data.email"}})],1),_c('div',{staticClass:"form-group position-relative"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.labels.PHONE_NUMBER))]),_c('span',{staticClass:"country-code px-2"},[_vm._v("+1")]),_c('text-field',{attrs:{"id":"mobileNumber","name":"mobileNumber","placeholder":"89999990","max-length":10,"type":"text","class-name":"textIndent","valid":!_vm.$v.form_data.mobileNumber.$error,"error-message":_vm.isSubmitted && !_vm.$v.form_data.mobileNumber.isValidPhoneNumber
                  ? 'Please enter a valid phone number'
                  : ''},model:{value:(_vm.form_data.mobileNumber),callback:function ($$v) {_vm.$set(_vm.form_data, "mobileNumber", $$v)},expression:"form_data.mobileNumber"}})],1),_c('div',{staticClass:"form-group mb-10 pt-2"},[_c('div',{staticClass:"custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.terms),expression:"terms"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.terms)?_vm._i(_vm.terms,null)>-1:(_vm.terms)},on:{"change":function($event){var $$a=_vm.terms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.terms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.terms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.terms=$$c}}}}),_c('label',{staticClass:"m-0 label-color pl-2"},[_vm._v(_vm._s(_vm.labels.AGREE_TO_FNF))]),_c('a',{staticClass:"pl-2 text-conditions terms-conditions",attrs:{"href":"/terms","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.labels.TERMS_N_CONDITION)+" ")]),_c('label',{staticClass:"m-0 label-color pl-2"},[_vm._v(" and ")]),_c('a',{staticClass:"pl-2 text-conditions terms-conditions",attrs:{"href":"/privacy","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.labels.PRIVACY_POLICY)+" ")])])]),_c('Button',{staticClass:"signup",attrs:{"disabled":!_vm.terms,"btn-class":_vm.buttonClass},on:{"click":_vm.register}},[_vm._v(" "+_vm._s(_vm.labels.SIGN_UP)+" ")])],1),_c('br'),_c('div',{staticClass:"text-center font-size-16 fw-400"},[_vm._v(" "+_vm._s(_vm.labels.ALREADY_HAVE_ACCOUNT)+" "),_c('a',{staticClass:"text-conditions signin",attrs:{"href":"/login"}},[_vm._v(_vm._s(_vm.labels.SIGN_IN))])])])])]),_c('loading',{attrs:{"active":_vm.isLoading,"color":"#9651F1","background-color":"#C9C9C9","height":100,"width":100}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }