<template>
  <div class="margin-top help-container">
    <!-- Child Profile Section -->
    <!-- Child Profile Section -->
    <!-- <div class="profile-section">
      <div class="child-title">Child Profile</div>
      <div class="profile-header">
        <div class="profile-info">
          <div class="info-group">
            <label>Name</label>
            <input
              type="text"
              v-model="child.name"
              :readonly="!isEditing"
              :class="{ editable: isEditing }"
            />
          </div>
          <div class="info-group">
            <label>Gender</label>
            <template v-if="!isEditing">
              <input type="text" v-model="child.gender" readonly />
            </template>
            <div v-else class="dropdown" @click="toggleDropdown('gender')">
              <div class="dropdown-selected">
                {{ child.gender }}
                <div class="svg-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="8"
                    viewBox="0 0 15 8"
                    fill="none"
                  >
                    <path
                      d="M13.5996 1L7.22906 6.75615L0.999609 0.999999"
                      stroke="#323232"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <ul v-if="isDropdownOpen.gender" class="dropdown-options">
                <li
                  v-for="option in genders"
                  :key="option"
                  @click="selectOption('gender', option)"
                >
                  {{ option }}
                </li>
              </ul>
            </div>
          </div>

          <div class="info-group">
            <label>Age</label>
            <template v-if="!isEditing">
              <input type="text" v-model="child.age" readonly />
            </template>
            <div v-else class="dropdown" @click="toggleDropdown('age')">
              <div class="dropdown-selected">
                {{ child.age }}
                <div class="svg-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="8"
                    viewBox="0 0 15 8"
                    fill="none"
                  >
                    <path
                      d="M13.5996 1L7.22906 6.75615L0.999609 0.999999"
                      stroke="#323232"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <ul v-if="isDropdownOpen.age" class="dropdown-options">
                <li
                  v-for="option in ages"
                  :key="option"
                  @click="selectOption('age', option)"
                >
                  {{ option }}
                </li>
              </ul>
            </div>
          </div>

          <div class="edit-svg" v-if="!isEditing">
            <svg
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.23585 12.6872L0.724609 13.8422L1.65728 10.2819L10.3632 1.07353C10.4103 1.02645 10.4742 1 10.5408 1C10.6074 1 10.6713 1.02645 10.7184 1.07353L12.9187 3.15833C12.943 3.18069 12.9626 3.20762 12.9765 3.23758C12.9903 3.26754 12.9982 3.29993 12.9995 3.33292C13.0008 3.3659 12.9957 3.39883 12.9843 3.42981C12.9729 3.4608 12.9555 3.48923 12.9331 3.51349L4.23585 12.6872Z"
                stroke="#323232"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.97656 2.53699L11.5465 4.9654"
                stroke="#323232"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.07129 12.5433L2.00685 13.4269"
                stroke="#323232"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M0 16.0048H10.681"
                stroke="#323232"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <button class="edit-btn" @click="toggleEdit">
            {{ isEditing ? "Save" : "Edit" }}
          </button>
        </div>
      </div>
    </div>  -->
    <!-- Membership Section -->
    <div class="profile-section">
      <div class="membership-section">
        <h3>{{ labels.PAYMENT_DETAILS }}</h3>
        <div class="membership-info">
          <div class="date-info">
            <div>
              <label>{{ labels.MEMBERSHIP_START_DATE }}</label>
              <div>{{ membership.start_date }}</div>
            </div>
            <div>
              <label>{{ labels.MEMBERSHIP_RENEWAL_DATE }}</label>
              <div>{{ membership.end_date }}</div>
            </div>
          </div>
        </div>
        <br />
        <div
          v-if="subscription_type == 1"
          class="trial-info"
        >
          <div v-if="isAdminValidatedForTrailPeriod === true">
            <div v-if="membership.remaining_days == 0 && isAdminValidatedForTrailPeriod">
              {{ labels.TRIAL_SESSION_OVER }}
            </div>
          </div>
          <div v-if="membership.remaining_days !== 0">
            Your free trial ends in {{ membership.remaining_days }} days
          </div>
          <div v-if="!isAdminValidatedForTrailPeriod && membership.remaining_days == 0">
            {{ labels.TRIAL_SESSION_OVER }}
          </div>
        </div>
        <div
          v-if="isAdminValidatedForSubscription"
          class="trial-info"
        >
          {{ labels.SUBSCRIPTION_EXPIRED }}
        </div>
        <div
          v-if="isSubscriptionEnds && !isAdminValidatedForSubscription"
          class="trial-info"
        >
          {{ labels.SUBSCRIPTION_EXPIRED }}
        </div>
        <br />
        <div class="subscription-container">
          <a
            href="#"
            class="manage-subscription"
            @click.prevent="handleSubscriptionClick"
          >
            Manage your subscription here.
          </a>
          <svg
            width="16"
            height="13"
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.80278 11.3781L14.7932 3.87811M14.7932 3.87811L7.60402 1.55603M14.7932 3.87811L13.1475 11.1576"
              stroke="#323232"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <!-- Success Message -->
        <!-- <div
          v-if="paymentSuccessMessage"
          class="success-message"
          style="color: green; margin-top: 10px; text-align: center"
        >
          {{ paymentSuccessMessage }}
        </div> -->
      </div>
    </div>
    <!-- Confirmation Popup -->
    <div
      v-if="showSubscriptionPopup"
      class="popup-overlay"
    >
      <div class="popup-content">
        <button
          class="modal-close-btn"
          @click="closeModal"
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.302 7.69809C17.1751 7.57125 17.0031 7.5 16.8237 7.5C16.6442 7.5 16.4722 7.57125 16.3453 7.69809L12.0876 11.9558L7.82997 7.69809C7.70237 7.57485 7.53146 7.50665 7.35407 7.50819C7.17667 7.50974 7.00698 7.58089 6.88154 7.70633C6.7561 7.83178 6.68494 8.00147 6.6834 8.17886C6.68186 8.35626 6.75005 8.52716 6.8733 8.65477L11.131 12.9124L6.8733 17.1701C6.80868 17.2325 6.75714 17.3072 6.72168 17.3897C6.68622 17.4723 6.66755 17.561 6.66677 17.6509C6.66599 17.7407 6.68311 17.8298 6.71713 17.913C6.75115 17.9961 6.80139 18.0716 6.86491 18.1352C6.92844 18.1987 7.00398 18.2489 7.08713 18.283C7.17027 18.317 7.25937 18.3341 7.3492 18.3333C7.43903 18.3325 7.52781 18.3139 7.61036 18.2784C7.6929 18.2429 7.76756 18.1914 7.82997 18.1268L12.0876 13.8691L16.3453 18.1268C16.4729 18.25 16.6438 18.3182 16.8212 18.3167C16.9986 18.3151 17.1683 18.244 17.2937 18.1185C17.4192 17.9931 17.4903 17.8234 17.4919 17.646C17.4934 17.4686 17.4252 17.2977 17.302 17.1701L13.0443 12.9124L17.302 8.65477C17.4288 8.52789 17.5001 8.35583 17.5001 8.17643C17.5001 7.99703 17.4288 7.82497 17.302 7.69809Z"
              fill="#323232"
            />
            <circle
              cx="12.5"
              cy="12.5"
              r="11.75"
              stroke="#323232"
              stroke-width="1.5"
            />
          </svg>
        </button>
        <!-- Peacock Image -->
        <img
          src="../../assets/FeatherCharacter.png"
          alt="Peacock Character"
          class="modal-image"
        />
        <div class="modal-title">
          {{ labels.SUBSCRIPTION_VALID }}
        </div>
        <div class="popup-actions">
          <button
            class="cancel-button"
            @click="cancelPopup"
          >
            {{ labels.CANCEL }}
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="profile-section">
      <div class="account-section">
        <h3>Account Information</h3>
        <div class="account-info">
          <div class="account-info-group">
            <label>Email Address</label>
            <input
              type="email"
              v-model="account.email"
              readonly
              class="non-editable"
            />
          </div>
          <div class="account-info-group">
            <label>Password</label>
            <div class="password-container">
              <input
                type="password"
                v-model="account.password"
                :readonly="editingField !== 'password'"
                :class="{ editable: editingField === 'password' }"
              />
              <a href="#" @click.prevent="toggleAccountEdit('password')">
                Change password
              </a>
            </div>
          </div>

          <div class="account-info-group">
            <label>Passcode</label>
            <div class="password-container">
              <input
                type="password"
                v-model="account.passcode"
                :readonly="editingField !== 'passcode'"
                :class="{ editable: editingField === 'passcode' }"
              />
              <a href="#" @click.prevent="toggleAccountEdit('passcode')">
                Change passcode
              </a>
            </div>
          </div>

          <div class="account-info-group">
            <label>Phone number</label>
            <input
              type="number"
              v-model="account.phone_number"
              :readonly="editingField !== 'phone_number'"
              :class="{ editable: editingField === 'phone_number' }"
            />
          </div>

          <div class="account-edit-svg" v-if="!isEditingAccount">
            <svg
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.23585 12.6872L0.724609 13.8422L1.65728 10.2819L10.3632 1.07353C10.4103 1.02645 10.4742 1 10.5408 1C10.6074 1 10.6713 1.02645 10.7184 1.07353L12.9187 3.15833C12.943 3.18069 12.9626 3.20762 12.9765 3.23758C12.9903 3.26754 12.9982 3.29993 12.9995 3.33292C13.0008 3.3659 12.9957 3.39883 12.9843 3.42981C12.9729 3.4608 12.9555 3.48923 12.9331 3.51349L4.23585 12.6872Z"
                stroke="#323232"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.97656 2.53699L11.5465 4.9654"
                stroke="#323232"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.07129 12.5433L2.00685 13.4269"
                stroke="#323232"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M0 16.0048H10.681"
                stroke="#323232"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <button
            class="account-edit-btn"
            @click="toggleAccountEdit(editingField || 'phone_number')"
          >
            {{ editingField || isEditingAccount ? "Save" : "Edit" }}
          </button>
        </div>
      </div>
    </div> -->
    <loading
      :active="isLoading"
      color="#9651F1"
      background-color="#C9C9C9"
      :height="100"
      :width="100"
    />
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import Loading from "vue-loading-overlay";
import { LABELS } from "@/constants/labels";

export default {
  name: "ChildProfile",
  components: { Loading },
  data() {
    return {
      isEditing: false,
      isDropdownOpen: { gender: false, age: false },
      child: {
        name: "",
        gender: "",
        age: null,
      },
      genders: ["Female", "Male"],
      ages: Array.from({ length: 6 }, (_, i) => i + 3),
      isEditingAccount: false,
      account: {
        email: "",
        password: "*****",
        passcode: "****",
        phone_number: "",
      },
      membership: {
        start_date: "",
        end_date: "",
        remaining_days: "",
      },
      editingField: null, // Keeps track of the currently editing field
      sessionId: null, // Will store the session ID from URL
      subscriptionDetails: null, // Holds subscription data
      errorMessage: "", // Holds error message, if any
      isLoading: false, // For showing the loader
      paymentSuccessMessage: "", // Holds the payment success message
      subscription_type: "",
      isSubscriptionEnds: false,
      showSubscriptionPopup: false, // Controls popup visibility
      subscription_is_valid: false,
      isAdminValidatedForTrailPeriod:false,
      isAdminValidatedForSubscription:false,
      labels: LABELS,
    };
  },
  created() {
    this.fetchUserProfile();
    // Get session ID from URL
    this.sessionId = new URLSearchParams(window.location.search).get(
      "session_id"
    );
    if (this.sessionId) {
      // Check if the session ID has already been processed
      const processedSessionIds =
        JSON.parse(localStorage.getItem("processedSessionIds")) || [];
      if (!processedSessionIds.includes(this.sessionId)) {
        this.sendStripeSessionDetails();
      }
    } else {
      this.errorMessage = "No session ID found in the URL.";
    }
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
    const subscritionExpired =
      localStorage.getItem("subscription_expired") === "true";
    this.isSubscriptionEnds = subscritionExpired;
    const isAdminValidatedForTrailPeriod =
      localStorage.getItem("admin_validated_for_trial_active") === "true";
      this.isAdminValidatedForTrailPeriod = isAdminValidatedForTrailPeriod;
    const isAdminValidatedForSubscription =
      localStorage.getItem("admin_validated_for_subscription") === "true";
      this.isAdminValidatedForSubscription = isAdminValidatedForSubscription;
  },
  methods: {
    getFormattedTimeZone() {
      const now = new Date();
      now.setHours(0, 0, 0, 0); // Reset time to 00:00:00
      return now.toISOString();
    },
    closeModal() {
      this.showSubscriptionPopup = false;
    },
    async handleSubscriptionClick() {
      this.subscription_is_valid = localStorage.getItem("subscription_expired");
      const isAdminValidated =
      localStorage.getItem("isAdminValidated") === "true";
      if (isAdminValidated) {
        // If isAdminValidated is true, redirect to Stripe immediately
        this.redirectToStripe();
      } else if (this.subscription_type === 2 && this.subscription_is_valid === "false") {
        // Show confirmation popup for valid subscription
        this.showSubscriptionPopup = true;
      } else {
        // Proceed to Stripe for new subscriptions
        this.redirectToStripe();
      }
    },
    cancelPopup() {
      this.showSubscriptionPopup = false;
    },
    async sendStripeSessionDetails() {
      // Show loader
      this.isLoading = true;
      try {
        const userEmail = localStorage.getItem("email");
        const payload = {
          stripe_session_id: this.sessionId,
          user_email: userEmail,
          subscription_type_id: 2,
          price: "14.99",
        };
        // Construct the URL without appending the payload
        const url = `${process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN}${process.env.VUE_APP_FLUTE_N_FEATHER_SAVE_PAYMENT_DETAILS}`;

        // Send the POST request with the payload as the second argument
        const response = await axios.post(url, payload);

        if (response && response.data) {
          // Mark the session ID as processed
          const processedSessionIds =
            JSON.parse(localStorage.getItem("processedSessionIds")) || [];
          processedSessionIds.push(this.sessionId);
          localStorage.setItem(
            "processedSessionIds",
            JSON.stringify(processedSessionIds)
          );
          localStorage.setItem("trial_expired", false);
          // Set payment success flag
          localStorage.setItem("paymentSuccess", "true");
          localStorage.setItem("subscription_expired", "false");
          // Navigate to the home page
          window.location.href = "/home";
          // Show success message
          //this.paymentSuccessMessage = "Payment was successful!";
          // setTimeout(() => {
          //   this.paymentSuccessMessage = "";
          // }, 5000); // Clear the message after 5 seconds
        }
      } catch (error) {
        console.error("Error fetching Stripe session details:", error);
        this.errorMessage = "There was an sending session details.";
      } finally {
        this.isLoading = false;
      }
    },
    toggleEdit() {
      if (this.isEditing) {
        this.saveProfileChanges();
      }
      this.isEditing = !this.isEditing;
      this.isDropdownOpen = { gender: false, age: false };
    },
    async saveProfileChanges() {
      try {
        const userEmail = localStorage.getItem("email");
        const encodedEmail = encodeURIComponent(userEmail);
        const payload = {
          user_email: encodedEmail,
          child_name: this.child.name,
          gender: this.child.gender,
          age: this.child.age,
          phone_number: this.account.phone_number,
          password: this.account.password,
          passcode: this.account.passcode,
        };
        await axios.post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_GET_USER_DETAILS +
            payload
        );
        // if (response && response.data) {
        //   console.log("Profile updated successfully:", response.data);
        // }
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    },
    toggleDropdown(type) {
      if (this.isEditing) {
        this.isDropdownOpen = {
          ...this.isDropdownOpen,
          [type]: !this.isDropdownOpen[type],
        };
      }
    },
    handleOutsideClick(event) {
      const profileSection = this.$el;
      if (!profileSection.contains(event.target)) {
        this.isDropdownOpen = { gender: false, age: false };
      }
    },
    beforeDestroy() {
      document.removeEventListener("click", this.handleOutsideClick);
    },
    selectOption(type, value) {
      this.child[type] = value;
      this.isDropdownOpen[type] = false;
    },
    // Toggle editing mode for account information
    toggleAccountEdit(field) {
      if (this.editingField === field) {
        this.saveAccountChanges();
        this.editingField = null; // Exit editing mode after saving
      } else {
        this.editingField = field; // Enable editing for the selected field
        if (field === "password") this.account.password = ""; // Clear password if editing
        if (field === "passcode") this.account.passcode = ""; // Clear passcode if editing
        this.isEditingAccount = !this.isEditingAccount;
      }
    },
    clearField(field) {
      // Clears the specific field when "Change" is clicked
      if (field === "password") this.account.password = "";
      if (field === "passcode") this.account.passcode = "";
      this.editingField = field; // Enable editing for the cleared field
    },
    async fetchUserProfile() {
      try {
        this.isLoading = true;
        const userEmail = localStorage.getItem("email");
        const encodedEmail = encodeURIComponent(userEmail);
        // Call the API to send the password reset email
        const response = await axios.get(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_GET_USER_DETAILS +
            "?user_email=" +
            encodedEmail
        );
        if (response && response.data) {
          this.isLoading = false;
          const data = response.data.user_details;
          this.child.name = data.child_name;
          this.child.gender = data.gender;
          this.child.age = data.age;
          this.account.email = data.email;
          this.account.phone_number = data.phone_number;
          this.account.password = data.password;
          this.account.passcode = data.passcode;
          this.membership.start_date = data.trial_subscription_start_date;
          this.membership.end_date = data.trial_subscription_end_date;
          this.membership.remaining_days = data.remaining_days;
          this.subscription_type = data.subscription_type;
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async saveAccountChanges() {
      try {
        const userEmail = localStorage.getItem("email");
        const payload = {
          user_email: userEmail,
          child_name: this.child.name,
          gender: this.child.gender,
          age: this.child.age,
          phone_number: this.account.phone_number,
          password: this.account.password,
          passcode: this.account.passcode,
        };
        const response = await axios.post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_GET_USER_DETAILS +
            payload
        );
        if (response && response.data) {
          setTimeout(() => {
            this.isEditingAccount = !this.isEditingAccount;
            this.editingField = null; // Reset editingField after save
          }, 500); // Simulate a slight delay for saving (e.g., API response
        }
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    },
    async redirectToStripe() {
      const user_email = localStorage.getItem("email");
      const price_id = process.env.VUE_APP_SUBSCRIPTION_PRICE_ID;
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      stripe
        .redirectToCheckout({
          lineItems: [{ price: price_id, quantity: 1 }],
          mode: "subscription",
          successUrl: `${
            window.location.origin
          }/parent_dashboard/profile?session_id={CHECKOUT_SESSION_ID}&cache_bust=${new Date().getTime()}`,
          cancelUrl: `${
            window.location.origin
          }/parent_dashboard/profile?cache_bust=${new Date().getTime()}`,
          customerEmail: user_email,
          billingAddressCollection: "required",
        })
        .then(() => {
          window.location.reload(true); // Force a full reload bypassing cache
        })
        .catch((error) => {
          console.error("Stripe checkout error:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  position: relative; /* Ensure the close button is positioned relative to this container */
  background: var(
    --intro-gradient-1,
    linear-gradient(0deg, #fff8e2 0.71%, #fff1c3 56.33%, #ffeaa6 99.97%)
  );
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  max-width: 400px; /* Limit the popup width */
  width: 90%; /* Make it responsive */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 170px;
}
.modal-title {
  color: #383838;
  font-family: Arial;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  margin-left: 115px;
  margin-top: 26px;
  text-align: center;
}

.popup-actions {
  margin-top: 20px;
}

.popup-actions button {
  margin: 0 10px;
  margin-top: 24px;
}
.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.modal-close-btn svg {
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease;
}

.modal-close-btn:hover svg {
  transform: scale(1.1);
}

.modal-image {
  width: 156px;
  position: absolute;
  left: 0;
}

.cancel-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  background: var(
    --intro-gradient-1,
    linear-gradient(0deg, #f7f1dd 0.71%, #f1e6bf 56.33%, #ffe89d 99.97%)
  );
}

.child-title {
  color: $text-color;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
}

input:focus {
  outline: none;
  border-bottom: 1px solid $black-color; /* Focus style */
}

.svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown {
  position: relative; /* Needed for dropdown-options positioning */
  width: 67%; /* Adjust to parent container */
  flex-grow: 1; /* Allow dropdown to take up space */
}

.dropdown-selected {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between text and icon */
  font-size: 17px;
  line-height: 32px;
  border-bottom: 1px solid #8b8a8a;
  padding: 0 10px; /* Add padding instead of margins */
  cursor: pointer;
}
@media (max-width: 1024px) {
  .dropdown {
    width: 72%;
  }
  .edit-btn {
    margin-right: -33px;
  }
}
.dropdown-options {
  position: absolute;
  top: 100%; /* Align just below the dropdown */
  left: 0;
  background-color: $white-color;
  list-style: none;
  padding: 5px 0;
  border: 1px solid #ccc;
  z-index: 10;
  width: 100%; /* Match the dropdown width */
}

.dropdown-options li {
  font-size: 16px;
  padding: 5px 10px; /* Add padding for clickable area */
  cursor: pointer;
}

.dropdown-options li:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

.profile-section {
  background: $dashboard-content-bg-color;
  border-radius: 10px;
  padding: 20px 30px;
  margin-bottom: 20px;
}

.profile-header {
  display: flex;
  gap: 20px;
  align-items: center;
}

.profile-info {
  display: flex;
  gap: 10px;
  flex-grow: 1;
  align-items: flex-end;
  position: relative;
}

.info-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  margin-left: 8.1%; /* Adjust margin to align under "i" */

  label {
    color: $black-color;
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    opacity: 0.7; /* Increased opacity for better readability */
    text-align: left; /* Align text to the left */
    margin: 0; /* Reset margin */
  }

  input {
    border: none;
    border-bottom: 1px solid #8b8a8a; /* Thin bottom line */
    background: none;
    height: 24px; /* Adjust for input height */
    outline: none;
    font-size: 14px;
    padding: 0 4px;
    color: $text-color;
    width: 60%; /* Adjust input width to fill container */
    max-width: 300px; /* Set a maximum width */
    margin: 0; /* Remove auto centering */
    &:focus {
      border-bottom: 1px solid $black-color; /* Highlight on focus */
    }

    &::placeholder {
      color: #8b8a8a;
      opacity: 0.5;
    }
  }
}
.non-editable {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.account-info-group {
  flex: 1 1 40%; /* Take up 45% of the row, adjust for spacing */
  display: flex;
  flex-direction: column;

  label {
    color: $black-color;
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    opacity: 0.5;
    margin-left: 10px;
    margin-top: 20px;
  }

  a {
    color: $black-color;
    font-family: Arial;
    font-size: 12px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    margin-top: -14px;
    opacity: 0.5;
  }
}
/* Media Query for Smaller Devices */
@media (max-width: 1024px) {
  .account-info-group {
    flex: 0 1 38%; /* Full width on smaller screens */
  }

  input {
    width: 50%; /* Shrink inputs on smaller devices */
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .account-info {
    padding: 10px; /* Add some padding */
  }

  .account-info-group {
    flex: 1 1 100%; /* Full width */
  }

  input {
    width: 100%; /* Ensure inputs take full width */
    margin-left: 0;
  }

  a {
    font-size: 10px; /* Smaller font for links */
  }

  label {
    font-size: 10px; /* Smaller font for labels */
  }
}
input {
  border: none;
  border-bottom: 1px solid #8b8a8a; /* Thin bottom line */
  background: none;
  height: 24px; /* Adjust for input height */
  outline: none;
  font-size: 14px;
  padding: 0 4px;
  color: $text-color;
  margin: 0 auto; /* Center the line */
  &:focus {
    border-bottom: 1px solid $black-color; /* Highlight on focus */
  }
  margin-left: 11px;
  &::placeholder {
    color: #8b8a8a;
    opacity: 0.5;
  }
}

.password-container {
  display: grid;
}

.password-container a {
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap; /* Prevent the link from breaking into two lines */
  margin-left: 100px; /* Push the link to the end of the container if needed */
  font-size: 14px; /* Adjust font size for better visibility on small screens */
}

@media (max-width: 1024px) {
  .password-container a {
    margin-left: 139px; /* Adjust margin for smaller screens */
    font-size: 12px; /* Slightly smaller font size for mobile */
  }
}

.edit-svg {
  width: 13px;
  height: 15px;
  flex-shrink: 0;
  margin: 0; /* Remove existing margins */
  position: absolute; /* Position it next to the button */
  right: 55px; /* Adjust the spacing relative to the button */
  top: 68%; /* Align vertically with the button */
  transform: translateY(-50%); /* Correct vertical alignment */
}
/* Media Query for Mobile Devices */
@media (max-width: 1024px) {
  .edit-svg {
    right: 20px; /* Move the SVG closer to the right edge */
  }
}

.account-edit-svg {
  width: 13px;
  height: 15px;
  flex-shrink: 0;
  margin: 0; /* Remove existing margins */
  position: absolute; /* Position it next to the button */
  right: 55px; /* Adjust the spacing relative to the button */
  transform: translateY(-50%); /* Correct vertical alignment */
  bottom: 1.1px;
}
/* Media Query for Mobile Devices */
@media (max-width: 1024px) {
  .account-info-group {
    flex: 0 1 38%;
  }
}
.edit-btn {
  position: absolute;
  right: 0;
  transform: translateY(-50%); /* Corrects the vertical alignment */
  padding: 5px 15px;
  color: $text-color;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* Matches dropdown line height */
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  z-index: 2; /* Ensure it appears above other elements */
  margin-bottom: -30px;
}

.account-edit-btn {
  position: absolute;
  right: 0;
  transform: translateY(-50%); /* Corrects the vertical alignment */
  padding: 5px 15px;
  color: $text-color;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 4px; /* Matches dropdown line height */
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  z-index: 2; /* Ensure it appears above other elements */
  bottom: 0;
}
.info-group input,
.info-group select {
  font-style: normal;
  font-weight: 400;
  margin-top: 6px;
}
.editable {
  border-color: #007bff;
  background-color: #f9f9f9;
}

.arrow {
  width: 15px;
  height: 11.087px;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: $text-color;
}

.subscription-container {
  display: flex;
  align-items: center;
  gap: 0px; /* Adjust the gap as needed */
}

.membership-section {
  margin: 30px 0;
  margin-top: 1px;
  h3 {
    color: $text-color;

    /* BODY - DESKTOP */
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
  }

  .membership-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .date-info {
    display: flex;
    gap: 40px;

    label {
      opacity: 0.5;
      color: $black-color;
      font-family: Arial;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.24px;
    }

    div {
      color: $text-color;
      /* MOBILE - BODY */
      font-family: Arial;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 164.706% */
    }
  }

  .trial-info {
    color: $text-color;

    /* H3 - DESKTOP INACTIVE */
    font-family: Arial;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 32.5px */
    letter-spacing: -0.5px;
  }

  .manage-subscription {
    color: $text-color;

    /* BODY - DESKTOP */
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
}

.account-section {
  h3 {
    color: $text-color;

    /* BODY - DESKTOP */
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
  }

  .account-info {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping to the next line */
    gap: 30px; /* Spacing between input groups */
    position: relative;
  }
}
</style>
