<template>
  <div class="container-fluid p-0 outer-layout">
    <div class="logo-container">
      <notifications
        group="dashboard"
        :duration="2000"
        width="100%"
      />
      <img
        src="../assets/fnf.png"
        alt="Vue"
        class="fnf-register-logo"
      />
    </div>
    <div
      class="row mx-0 justify-content-center register-container align-items-center container-min-height pt-5per pb-5per"
    >
      <div class="registerCard">
        <div class="col-12 col-sm-12 col-md-12 col-xl-12 p-20">
          <div class="registerHeader pb-1">
            {{ labels.WELCOME_TO_FLUTE_N_FEATHER }}
          </div>
          <div class="registerSecondaryHeader pb-2">
            {{ labels.YOUR_CHILDS_PERSONAL_COMPANION }}
          </div>
          <form>
            <div class="form-group">
              <label class="label">{{ labels.CHILDS_OR_TEACHERS_NAME }}<span class="text-danger">
                *</span></label>
              <text-field
                id="fullName"
                v-model="form_data.fullName"
                name="fullName"
                placeholder="Enter your child's name"
                :valid="!$v.form_data.fullName.$error"
                :error-message="
                  isSubmitted && !$v.form_data.fullName.required
                    ? 'Full Name is required'
                    : !$v.form_data.fullName.regex
                      ? 'Only alphabetic characters and spaces are allowed'
                      : ''
                "
              />
            </div>
            <div class="form-group">
              <div class="row m-0">
                <div
                  class="col-12 col-sm-12 col-md-12 col-xl-12 p-0 form-group"
                >
                  <label class="label">{{ labels.CHILDS_AGE }}<span
                    class="text-danger"
                  >
                    *</span></label>
                  <v-select
                    v-model="form_data.age"
                    class="custom-v-select"
                    placeholder="Choose"
                    :class="{ 'is-invalid': !$v.form_data.age.$error }"
                    :searchable="false"
                    :clearable="false"
                    :options="ageArr"
                  />
                  <span
                    v-if="isSubmitted && !$v.form_data.age.required"
                    class="error"
                  >Age is required</span>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-xl-12 p-0">
                  <label class="label">{{ labels.CHILDS_GENDER }}</label>
                  <v-select
                    v-model="form_data.gender"
                    class="custom-v-select"
                    placeholder="Choose"
                    :searchable="false"
                    :clearable="false"
                    :options="genderArr"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="label">{{ labels.EMAIL }}<span class="text-danger"> *</span></label>
              <text-field
                id="email"
                v-model="form_data.email"
                name="email"
                placeholder="Enter your email address"
                :valid="!$v.form_data.email.$error"
                :error-message="
                  isSubmitted && !$v.form_data.email.required
                    ? 'Email is required'
                    : !$v.form_data.email.email
                      ? 'Please enter a valid email address'
                      : ''
                "
              />
            </div>
            <div class="form-group position-relative">
              <label class="label">{{ labels.PHONE_NUMBER }}</label>
              <span class="country-code px-2">+1</span>
              <text-field
                id="mobileNumber"
                v-model="form_data.mobileNumber"
                name="mobileNumber"
                placeholder="89999990"
                :max-length="10"
                type="text"
                class-name="textIndent"
                :valid="!$v.form_data.mobileNumber.$error"
                :error-message="
                  isSubmitted && !$v.form_data.mobileNumber.isValidPhoneNumber
                    ? 'Please enter a valid phone number'
                    : ''
                "
              />
            </div>
            <div class="form-group">
              <label class="label">{{ labels.CHOOSE_PASSWORD }}<span class="text-danger"> *</span></label>
              <div class="position-relative">
                <text-field
                  id="password"
                  v-model="form_data.password"
                  name="password"
                  placeholder="Enter password"
                  :type="showPassword ? 'text' : 'password'"
                  :valid="!$v.form_data.password.$error"
                  :error-message="
                    isSubmitted && !$v.form_data.password.required
                      ? 'Password is required'
                      : ''
                  "
                />
                <button
                  class="password-toggle"
                  @click="togglePasswordVisibility"
                >
                  <img
                    v-if="showPassword"
                    class="showPassword"
                    src="../assets/eye-filled.png"
                    alt="Show Password"
                  />
                  <img
                    v-else
                    src="../assets/hide.png"
                    alt="Hide Password"
                  />
                </button>
              </div>
            </div>
            <div class="form-group">
              <label class="label">{{ labels.CONFIRM_PASSWORD }}<span class="text-danger"> *</span></label>
              <div class="position-relative">
                <text-field
                  id="confirmPassword"
                  v-model="form_data.confirmPassword"
                  name="confirmPassword"
                  :read-only="isConfirmPasswordDisabled"
                  placeholder="Confirm your password"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  :valid="!$v.form_data.confirmPassword.$error"
                  :error-message="
                    isSubmitted && !$v.form_data.confirmPassword.required
                      ? 'Please confirm your password.'
                      : !$v.form_data.confirmPassword.sameAsPassword
                        ? 'Passwords do not match'
                        : ''
                  "
                />
                <button
                  class="password-toggle"
                  @click="toggleConfirmPasswordVisibility"
                >
                  <img
                    v-if="showConfirmPassword"
                    class="showPassword"
                    src="../assets/eye-filled.png"
                    alt="Show Password"
                  />
                  <img
                    v-else
                    src="../assets/hide.png"
                    alt="Hide Password"
                  />
                </button>
              </div>
            </div>
            <div class="form-group mb-10 pt-2">
              <div class="custom-checkbox">
                <input
                  v-model="terms"
                  type="checkbox"
                />
                <label class="m-0 label-color pl-2">
                  {{ labels.AGREE_TO_FNF }}</label>
                <a
                  href="/terms"
                  target="_blank"
                  class="pl-2 text-conditions terms-conditions"
                >
                  {{ labels.TERMS_N_CONDITION }}
                </a>
                <label class="m-0 label-color pl-2"> and </label>
                <a
                  href="/privacy"
                  target="_blank"
                  class="pl-2 text-conditions terms-conditions"
                >
                  {{ labels.PRIVACY_POLICY }}
                </a>
              </div>
            </div>
            <Button
              :disabled="!terms"
              :btn-class="buttonClass"
              class="signup"
              @click="register"
            >
              {{ labels.SIGN_UP }}
            </Button>
          </form>
          <br />
          <div class="text-center font-size-16 fw-400">
            {{ labels.ALREADY_HAVE_ACCOUNT }}
            <a
              href="/login"
              class="text-conditions signin"
            >{{ labels.SIGN_IN }}</a>
          </div>
        </div>
      </div>
    </div>
    <loading
      :active="isLoading"
      color="#9651F1"
      background-color="#C9C9C9"
      :height="100"
      :width="100"
    />
  </div>
</template>
<script>
import TextField from "@/components/common/TextField.vue";
import Button from "./common/ButtonComponent.vue";
import { required, email, sameAs } from "vuelidate/lib/validators";
import axios from "axios";
import Loading from "vue-loading-overlay";
import { updateTitle } from "@/utils/utils";
import { LABELS } from "@/constants/labels";

export default {
  name: "RegisterComponent",
  components: { TextField, Button, Loading },
  data() {
    return {
      form_data: {
        fullName: null,
        age: null,
        gender: null,
        email: null,
        mobileNumber: null,
        password: null,
        confirmPassword: null,
      },
      terms: false,
      isConfirmPasswordDisabled: true,
      isSubmitted: false,
      isLoading: false,
      showPassword: false,
      showConfirmPassword: false,
      ageArr: [
        { id: 3, label: "3" },
        { id: 4, label: "4" },
        { id: 5, label: "5" },
        { id: 6, label: "6" },
        { id: 7, label: "7" },
        { id: 8, label: "8" },
        { id: 9, label: "9" },
        { id: 10, label: "10" },
        { id: 11, label: "11" },
      ],
      genderArr: [
        { id: "male", label: "Male" },
        { id: "female", label: "Female" },
      ],
      labels: LABELS,
    };
  },
  computed: {
    buttonClass() {
      // If terms are not accepted, apply the disabled style
      return !this.terms ? "bg-disabled" : "";
    },
  },
  watch: {
    "form_data.password": function (newValue) {
      this.isConfirmPasswordDisabled = !newValue;
      this.form_data.confirmPassword = "";
    },
  },
  validations: {
    form_data: {
      fullName: {
        required,
        isValidFullName(value) {
          if (!value) return true;
          return /^[A-Za-z\s]+$/.test(value);
        },
      },
     age: { required },
      email: { required, email },
      mobileNumber: {
        isValidPhoneNumber(value) {
          if (!value) return true;
          return /^\d+$/.test(value);
        },
      },
      password: { required },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  mounted() {
    updateTitle("Sign up");
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    register() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (!this.terms) {
        return;
      }
      this.isLoading = true;
      let dataTosend = {};
      (dataTosend.username = this.form_data.fullName.trim()),
        (dataTosend.email = this.form_data.email.trim().toLowerCase()),
        (dataTosend.age = this.form_data.age.id),
        (dataTosend.gender = this.form_data.gender ? this.form_data.gender.id : ''),
        (dataTosend.phone_number = this.form_data.mobileNumber
          ? this.form_data.mobileNumber
          : null),
        (dataTosend.password = this.form_data.password.trim()),
        (dataTosend.is_google = false);
      axios
        .post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_REGISTER_BASE_API_URL_PATH,
          dataTosend
        )
        .then(() => {
          this.$router.push("/login");
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            group: "foo",
            type: "error",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "Registration failed.",
          });
        });
    },
    togglePasswordVisibility(event) {
      event.preventDefault();
      this.showPassword = !this.showPassword; // Toggle visibility
    },
    toggleConfirmPasswordVisibility(event) {
      event.preventDefault();
      this.showConfirmPassword = !this.showConfirmPassword;
      this.disabled = !this.form_data.password;
    },
    handleKeyDown(event) {
      if (event.key === "Enter" && this.terms) {
        event.preventDefault();
        this.register();
      }
    },
    isValidFullName(value) {
      if (!value) return true;
      return /^[A-Za-z\s]+$/.test(value);
    },
  },
};
</script>
<style lang="scss">
@import "../scss/global.scss";
@import "@/scss/color.scss";
.outer-layout {
  background: $dashboard-content-bg-color;
  min-height: 100vh;
}
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}
.logo-container {
  margin-bottom: auto;
}
.fnf-register-logo {
  margin-bottom: -5rem;
  margin-left: 20px;
}
.signup {
  color: rgba(50, 50, 50, 0.7);

  /* SM BODY - DESKTOP */
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 19.8px */
  letter-spacing: -0.36px;
}
.signin {
  color: $text-color;
  /* SM BODY - DESKTOP */
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 19.8px */
  letter-spacing: -0.36px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
.registerCard {
  width: 456px;
  border-radius: 47px 45px 50px 50px;
  background: $white-color;
  @media all and (min-width: 400px) and (max-width: 600px) {
    width: 342px !important;
  }
  @media all and (max-width: 400px) {
    width: 300px !important;
  }
}
.terms-conditions {
  color: $text-color;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
}
.registerHeader {
  color: $black-color;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media all and (max-width: 400px) {
    font-size: 20px;
  }
}
.registerSecondaryHeader {
  color: $black-color;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media all and (max-width: 400px) {
    font-size: 14px;
  }
}
.country-code {
  border-right: 1px solid #5e6366 !important;
  position: absolute;
  left: 4px;
  top: 34px;
  font-size: 16px;
}
.textIndent {
  text-indent: 33px;
  padding-left: 20px !important;
}
@media (max-width: 600px) {
  .signin-title {
    width: 220px; /* Set width for mobile screens */
  }
}
.password-toggle {
  position: absolute;
  right: 20px;
  top: 25px;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  cursor: pointer;
}
.vs__dropdown-option {
  margin-left: 0px;
}
.vs__dropdown-option--highlight {
  background-color: #9651f1 !important;
  color: $white-color !important;
}
.vs__dropdown-toggle {
  height: 48px;
  border: none !important;
  background: $dashboard-content-bg-color !important;
  color: #5e6366 !important;
  // border-radius: 16px !important;
}
.vs__actions {
  padding: 23px !important;
}
.showPassword {
  height: 21px;
}
.custom-checkbox {
  padding-left: 5px;
  position: relative;
}
.bg-disabled {
  border: none;
}
.ageResponsive {
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
  @media (min-width: 600px) {
    padding-right: 5px !important;
  }
}
.genderResponsive {
  @media (min-width: 600px) {
    padding-left: 5px !important;
  }
}
/* Change background color on hover */
.vs__dropdown-menu .vs__dropdown-option:hover {
  background-color: $dashboard-content-bg-color !important; /* Change to your preferred hover color */
  color: #333 !important; /* Optional: Change text color */
}

/* Change background color for selected option */
.vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--selected .vs__dropdown-option--highlight {
  background-color: $dashboard-content-bg-color !important; /* Change to your preferred selected color */
  color: $black-color !important; /* Optional: Change text color */
}
/* Change background color when an option is highlighted (using arrow keys) */
.vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--highlight {
  background-color: $dashboard-content-bg-color !important; /* Example: Orange highlight */
  color: $black-color !important;
}

/* Ensure the selected option persists after closing dropdown */
.vs__selected-option {
  background-color: $dashboard-content-bg-color !important;
  color: $black-color!important;
}
</style>
