<template>
  <div>
    <ValidatePasscodeComponent
      v-if="!isAuthenticated"
      @passcodeSuccess="onPasscodeSuccess"
    />
    <div
      v-else
      class="container-fluid dashboard-page"
    >
      <div class="row m-0 container-min-height dashboard-padding">
        <div
          class="col-12 col-sm-12 col-md-12 col-xl-12 bg-white dashboard-container"
        >
          <!-- Header -->
          <div class="row m-0 mb-5">
            <div class="dashboard-title">
              {{ labels.PARENT_DASHBOARD }}
            </div>
            <div
              class="menu-button ml-auto"
              @click="backToHome"
            >
              <img
                class="pr-1"
                src="../../assets/Home_1.svg"
                alt=""
              />{{ labels.HOME }}
            </div>
          </div>
          <!-- Tabs -->
          <div class="col-12 p-0">
            <div class="tabs-container">
              <ul class="nav nav-tabs">
                <li
                  v-for="(tab, index) in dashboardTabs"
                  :key="index"
                  class="nav-item"
                  :class="{ disabled: tab.disabled }"
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeTab === tab.name, disabled: tab.disabled }"
                    href="#"
                    @click.prevent="!tab.disabled && selectTab(tab.name, index)"
                  >
                    {{ tab.label }}
                  </a>
                </li>
              </ul>
              <div class="tab-indicator">
                <!-- Highlight the active tab's underline -->
                <div
                  class="indicator-line"
                  :style="{
                    width: activeTabWidth + 'px',
                    left: activeTabOffset + 'px',
                  }"
                ></div>
              </div>
              <div class="tab-content">
                <!-- Dynamically render the component based on activeTab -->
                <component :is="activeComponent" />
              </div>
            </div>
          </div>
          <!-- Render child components -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SummaryComponent from "@/components/ParentDashboard/SummaryComponent.vue";
import ProfileComponent from "@/components/ParentDashboard/ProfileComponent.vue";
import SettingsComponent from "@/components/ParentDashboard/SettingsComponent.vue"; // Adjust path as needed
import HelpComponent from "@/components/ParentDashboard/HelpComponent.vue";
import ValidatePasscodeComponent from "../ParentPasscode/ValidatePasscodeComponent.vue";
import { LABELS } from "@/constants/labels";

export default {
  name: "ParentDashboardComponent",
  components: {
    SummaryComponent,
    ProfileComponent,
    SettingsComponent,
    HelpComponent,
    ValidatePasscodeComponent,
  },
     beforeRouteLeave(to, from, next) {
    // Reset authentication state when leaving the dashboard
    this.isAuthenticated = false;
    localStorage.removeItem("isAuthenticated");
    next();
  },
  data() {
    return {
      dashboardTabs: [
        { name: "summary", label: "Summary", component: "SummaryComponent" },
        { name: "profile", label: "Profile", component: "ProfileComponent" },
        // { name: "settings", label: "Settings", component: "SettingsComponent", disabled: false },
        { name: "help", label: "Help", component: "HelpComponent" },
      ],
      activeTab: "summary", // Default active tab
      activeTabIndex: 0, // Default index for indicator
      activeTabWidth: 0, // Width of the active tab
      activeTabOffset: 0, // Left offset of the active tab
      isAuthenticated: false, // Default state for authentication
      isTrialOver: localStorage.getItem("trial_expired") === "true",
      isAdminValidated: localStorage.getItem("isAdminValidated"),
      isFreeTrialOver: false,
      isSubscriptionActive: false,
      labels: LABELS,
    };
  },
  computed: {
    activeComponent() {
      // Find the component name from the active tab
      const activeTabObj = this.dashboardTabs.find(
        (tab) => tab.name === this.activeTab
      );
      return activeTabObj ? activeTabObj.component : null;
    },
  },
watch: {
  $route() {
    this.updateActiveTab(); // Sync active tab with route changes
  },
  activeTab() {
    this.updateIndicatorPosition(); // Update the tab indicator on tab change
  },
},
   mounted() {
     const isSubscriptionActive = localStorage.getItem("subscription_expired") === "true"; 
      this.isSubscriptionActive = isSubscriptionActive;
     if (this.isAdminValidated) {
      this.isFreeTrialOver = false;      
    } else {
      this.isFreeTrialOver = this.isTrialOver;
    }
      // Dynamically disable settings tab if trial is over
  this.dashboardTabs = this.dashboardTabs.map((tab) =>
    tab.name === "settings" ? { ...tab, disabled: (this.isFreeTrialOver || this.isSubscriptionActive) } : tab
  );
    // Once enter the passcode on page repload it should stay in dashboard page not passcode page
    const storedAuthState = localStorage.getItem("isAuthenticated");
    this.isAuthenticated = storedAuthState === "true";

    const fromSetupPasscode = localStorage.getItem("from_setup_passcode");
    if (fromSetupPasscode) {
      // Bypass ValidatePasscodeComponent if coming from setup page
      this.isAuthenticated = true;
      localStorage.setItem("isAuthenticated", "true"); // Persist the state
      localStorage.removeItem("from_setup_passcode"); // Clear the flag after use
    }

    if (this.isAuthenticated) {
      this.updateIndicatorPosition();
      window.addEventListener("resize", this.updateIndicatorPosition);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIndicatorPosition);
  },
  created() {
    // Set the active tab based on the initial route
    this.updateActiveTab();
  },
  methods: {
    navigateToTab(tabName) {
      const targetRoute = `/parent_dashboard/${tabName}`;
      if (this.$route.path !== targetRoute) {
        this.$router.push(targetRoute);
      }
    },
    updateActiveTab() {
      // Update active tab based on current route
      const currentTab = this.dashboardTabs.find(
        (tab) => `/parent_dashboard/${tab.name}` === this.$route.path
      );
      if (currentTab) {
        this.activeTab = currentTab.name;
        this.activeTabIndex = this.dashboardTabs.indexOf(currentTab); // Update active tab index
        this.updateIndicatorPosition(); // Update tab indicator position
      }
    },
    setActiveTab(tabName) {
      if (this.dashboardTabs.some((tab) => tab.name === tabName)) {
        this.activeTab = tabName;
      }
    },
    selectTab(tabName, tabIndex) {
      this.activeTab = tabName;
      this.activeTabIndex = tabIndex;
      this.updateIndicatorPosition();
      this.navigateToTab(tabName); // Update the route
    },
    updateIndicatorPosition() {
      // Get the active tab element
      this.$nextTick(() => {
        const activeTabElement = this.$el.querySelector(".nav-link.active");
        if (activeTabElement) {
          this.activeTabWidth = activeTabElement.offsetWidth;
          this.activeTabOffset = activeTabElement.offsetLeft;
        }
      });
    },
    backToHome() {
      this.$router.push("/home");
    },
    onPasscodeSuccess() {
      // Callback for successful passcode entry
      localStorage.setItem("isAuthenticated", "true"); // Persist authentication state
      this.isAuthenticated = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.dashboard-page {
  background-color: rgba($dashboard-bg-color, 0.5);
}
.dashboard-padding {
  padding: 30px 25px 30px 25px;
}
.dashboard-container {
  padding: 30px !important;
  border-radius: 10px;
}
.dashboard-title {
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $text-color;
}
.menu-button {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  border-radius: 10px;
  background: $btn-color;
  border: none;
  width: 150px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.menu-button:hover {
  background-color: #e0dbc5;
}
.tabs-container {
  position: relative;
}
.nav-tabs {
  display: flex;
  justify-content: flex-start;
  gap: 5rem;
  padding: 0;
  list-style: none;
  border-bottom: none;
}

.nav-item {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
}

.nav-link {
  text-decoration: none;
  border: none;
  padding: 0px !important;
  color: $text-color;
}

.nav-link.active {
  color: $text-color;
  font-weight: 700;
}

.nav-link:hover {
  color: #333 !important;
}
.tab-indicator {
  position: relative;
  height: 2px;
  background: var(--copy-color, #8b8a8a);
  opacity: 0.5;
  width: 100%;
}

.indicator-line {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--copy-color, $text-color);
  transition: all 0.3s ease;
}
@media (max-width: 960px) {
  .dashboard-title {
    font-size: 22px;
  }
}
</style>
