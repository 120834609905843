<template>
  <div class="container-fluid">
    <!-- Sidebar for non-topic details page -->
    <div
      v-if="!isTopicDetailsPage"
      class="sidebar"
    >
      <div class="logo-container">
        <img
          :src="
            require('@/assets/Group 1707478419.svg') +
              '?v=' +
              new Date().getTime()
          "
          alt="FnF Logo"
          class="fnf-logo cursor-pointer"
          @click="homePage"
        />
      </div>
      <div
        v-if="!isFunTopic && !isFreeTrialOver && !isSubscriptionActive"
        class="microphone-container tooltip-container"
      >
        <button
          class="button"
          @mouseenter="showTooltip"
          @click="fun"
        >
          <img
            :src="
              require('@/assets/side_bar_fun.svg') +
                '?v=' +
                new Date().getTime()
            "
            alt="Microphone Icon"
            class="microphone-icon"
          />
        </button>
        <div
          v-if="isTooltipVisible"
          class="tooltip-text click-me-tootltip"
        >
          <button
            class="close-btn"
            @click="closeTooltip"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
            >
              <line
                x1="9.54594"
                y1="3.18192"
                x2="3.18198"
                y2="9.54588"
                stroke="white"
              />
              <line
                x1="3.18204"
                y1="3.18209"
                x2="9.54601"
                y2="9.54605"
                stroke="white"
              />
            </svg>
          </button>
          <span>{{ labels.FUN_TOOLTIP_TEXT }}</span>
        </div>
      </div>
      <div class="icon-container">
        <button
          class="sidebar-icon button tooltip-container"
          @click="profileMenu"
        >
          <img
            :src="
              require('@/assets/profile_icon.svg') +
                '?v=' +
                new Date().getTime()
            "
            alt="Profile Icon"
          />
          <span class="tooltip-text profile-tooltip">{{
            labels.PARENT_DASHBOARD
          }}</span>
        </button>
        <!-- <button class="sidebar-icon button">
          <img src="../../assets/settings_icon.png" alt="Settings Icon" />
        </button> -->
        <button
          class="sidebar-icon button tooltip-container"
          @click="LogoutModal"
        >
          <img
            :src="
              require('@/assets/logout_icon.svg') + '?v=' + new Date().getTime()
            "
            alt="Logout Icon"
          />
          <span class="tooltip-text logout-tooltip">{{ labels.LOGOUT }}</span>
        </button>
      </div>
    </div>

    <!-- Sidebar for topic details page -->
    <div
      v-else
      class="topic-sidebar"
      :class="isTopicDetailsPage ? 'topic-sidebar-width' : ''"
    >
      <div class="sidebar-content">
        <span>{{ truncateText(subjectName, 10, 15) }}</span>
        <span class="separator">|</span>
        <span>{{ truncateText(categoryName, 10, 15) }}</span>
        <span class="separator">|</span>
        <span class="topicName">{{ topicName }}</span>
      </div>
    </div>

    <!-- Logout Confirmation Modal -->
    <div
      v-if="showLogoutModal"
      class="sidebar-overlay"
    >
      <div class="modal-content">
        <!-- Modal Header -->
        <!-- <div class="modal-header">
          <button @click="showLogoutModal = false" class="close-button">&times;</button>
        </div> -->
        <!-- Modal Body -->
        <div class="modal-body">
          <span>{{ labels.SIGN_OUT_TEXT }}</span>
        </div>
        <!-- Modal Footer -->
        <div class="modal-footer">
          <button
            class="cancel-logout"
            @click="showLogoutModal = false"
          >
            {{ labels.CANCEL }}
          </button>
          <button
            class="confirm-logout"
            @click="confirmLogout"
          >
            {{ labels.SIGN_OUT }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LABELS } from "@/constants/labels";
export default {
  name: "SidebarComponent",
  data() {
    return {
      showLogoutModal: false,
      isTooltipVisible: false,
      isTooltipBeingClosed: false,
      isFreeTrialOver: false,
      isSubscriptionActive: false,
      labels: LABELS,
    };
  },
  computed: {
    isFunTopic() {
      return this.$route.name === "Fun";
    },
    isParentDashboard() {
      return this.$route.name === "ParentDashboardComponent";
    },
    freeTrail() {
      return localStorage.getItem("trial_expired") === "true";
    },
    isAdminValidated() {
      return localStorage.getItem("isAdminValidated") === "true";
    },
    subscriptionActive() {
      return localStorage.getItem("subscription_expired") === "true";
    },
    showMicrophone() {
      // const isOnlyAdminValidated = this.isAdminValidated; // Check isAdminValidated alone
      const result =
        !this.isFunTopic && !this.isParentDashboard && !this.freeTrail;
      // this.isAdminValidated;
      return {
        result, // Combined result
      };
    },
    isTopic() {
      return this.$route.name === "TopicDetailsComponentNew";
    },
    isTopicDetailsPage() {
      return this.$route.name === "topicDetails";
    },
    subjectName() {
      return (
        this.$store.getters.getSubjectName ||
        localStorage.getItem("subjectName")
      );
    },
    categoryName() {
      return (
        this.$store.getters.getCategoryName ||
        localStorage.getItem("categoryName")
      );
    },
    topicName() {
      return (
        this.$store.getters.getTopicName || localStorage.getItem("topicName")
      );
    },
  },
  watch: {
    // Reset isTooltipBeingClosed when showing tooltip again
    isTooltipVisible(newVal) {
      if (newVal) {
        this.isTooltipBeingClosed = false; // Reset closed state when tooltip shows up again
      }
    },
  },
  mounted() {
    if (this.isAdminValidated) {
      this.isFreeTrialOver = false;
    } else {
      this.isFreeTrialOver = this.freeTrail;
      this.isSubscriptionActive = this.subscriptionActive;
    }
  },
  methods: {
    truncateText(text, maxLengthMobile, maxLengthDesktop) {
      const isMobile = window.innerWidth <= 956; // Adjust the breakpoint for mobile
      const maxLength = isMobile ? maxLengthMobile : maxLengthDesktop;

      return text.length > maxLength
        ? text.slice(0, maxLength).concat("...")
        : text;
    },
    LogoutModal() {
      this.showLogoutModal = true;
    },
    confirmLogout() {
      this.$store.dispatch("logout");
    },
    fun() {
      this.$router.push("/fun");
    },
    settings() {
      this.$router.push("/settings");
    },
    profileMenu() {
      if (this.$route.path !== "/parent_dashboard/summary") {
        this.$router.push("/parent_dashboard/summary");
      }
    },
    homePage() {
      if (this.$route.path !== "/home" && this.$route.path !== "/") {
        this.$router.push("/home");
      }
    },
    showTooltip() {
      if (!this.isTooltipBeingClosed) {
        this.isTooltipVisible = true; // Show tooltip on hover
      }
    },
    closeTooltip() {
      this.isTooltipBeingClosed = false;
      this.isTooltipVisible = false; // Hide the tooltip
    },
  },
};
</script>

<style scoped>
.container-fluid {
  display: flex;
  position: absolute; /* Positions relative to the viewport */
  top: 0; /* Start at the top of the viewport */
  left: 0; /* Start at the left of the viewport */
  width: 100%; /* Full width */
  min-height: 100vh; /* Keep the height as desired */
  overflow: hidden; /* Prevent scrollbars */
  box-sizing: border-box; /* Include padding in size calculation */
  padding: 20px; /* Default padding */

  /* Extra small devices: Portrait phones, smaller than 576px */
  @media (max-width: 575.98px) and (max-height: 640px) {
    padding: 10px; /* Reduce padding for smaller screens */
    min-height: 100vh; /* Adjust height for shorter devices */
  }

  /* Small devices: Landscape phones and smaller tablets */
  @media (min-width: 576px) and (max-width: 767.98px) and (max-height: 800px) {
    padding: 15px; /* Slightly larger padding */
    min-height: 100vh; /* Adjust height for landscape phones */
  }

  /* Medium devices: Tablets */
  @media (min-width: 768px) and (max-width: 1023.98px) and (max-height: 1024px) {
    padding: 20px; /* Default padding for medium screens */
    min-height: 100vh; /* Adjust height for tablets */
  }

  /* Large devices: Small laptops and desktops */
  @media (min-width: 1024px) and (max-width: 1365.98px) and (max-height: 1200px) {
    padding: 25px; /* Increase padding slightly */
    min-height: 100vh; /* Adjust height for small desktops */
  }

  /* Extra-large devices: Desktops and larger laptops */
  @media (min-width: 1366px) {
    padding: 30px; /* Ample padding for large screens */
    min-height: 100vh; /* Default full height for larger desktops */
  }
}
.topic-sidebar-width {
  width: 60px !important;
}
.sidebar,
.topic-sidebar {
  width: 70px;
  background-color: #fbf8ec;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  position: fixed; /* Make the sidebar sticky */
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 4999; /* Ensure it stays above other elements */
  height: 100vh; /* Full height of the viewport */
  box-sizing: border-box; /* Include padding in height calculation */
  /* overflow: hidden; Prevent any overflow from content */
}

/* Ensure it remains fixed on smaller devices */
@media only screen and (max-width: 1024px) {
  .sidebar,
  .topic-sidebar {
    width: 70px; /* Adjust width for tablets and smaller screens */
  }
}

@media only screen and (max-width: 576px) {
  .sidebar,
  .topic-sidebar {
    width: 70px; /* Further adjust width for smaller mobile phones */
  }
}

.logo-container {
  margin-bottom: auto;
}

/* Microphone Centered */
.microphone-container {
  margin: auto 0; /* Push to the center */
  display: flex;
  justify-content: center;
  align-items: center;
}

.fnf-logo {
  width: 52px;
  height: 40px;
  margin-bottom: 1rem;
}
.button {
  background: none;
  border: none;
  padding: 0;
  position: relative;
}

.button:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.icon-container {
  position: relative; /* Position relative within the fixed sidebar */
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Add spacing between icons */
  margin-top: auto; /* Push to the bottom of the sidebar */
  margin-bottom: 1rem; /* Add spacing at the bottom */
}

/* Adjust sidebar icon position for all mobile devices */
@media only screen and (max-width: 1024px) {
  /* Common breakpoint for tablets and smaller devices */
  .icon-container {
    margin-bottom: 2rem; /* Optional spacing at the bottom */
  }
}
/* Adjust sidebar icon position for all mobile devices */
@media only screen and (max-width: 768px) {
  /* Common breakpoint for tablets and smaller devices */
  .icon-container {
    margin-bottom: 2rem; /* Optional spacing at the bottom */
  }
}

@media only screen and (max-width: 576px) {
  /* Common breakpoint for mobile phones */
  .icon-container {
    margin-bottom: 2rem; /* Increase bottom margin for smaller devices */
  }
}

.microphone-icon {
  width: 4rem;
}

.sidebar-icon {
  display: inline-block !important;
  width: 32px !important;
  height: 38px !important;
  justify-content: center;
  align-items: center;
}
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4999;
}

.modal-content {
  width: auto;
  background-color: #ede6cf;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: flex-end;
  border: none;
  padding: 0px;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

/* Modal Body */
.modal-body {
  padding: 10px 0;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

/* Modal Footer */
.modal-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  border: none;
}

/* Modal Buttons */
.confirm-logout,
.cancel-logout {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.confirm-logout {
  background-color: #9651f1;
  color: #fff;
}

.confirm-logout:hover {
  background-color: #784eaf;
}

.cancel-logout {
  background-color: #cccccc;
  color: #000;
}

.cancel-logout:hover {
  background-color: #b3b3b3;
}
.sidebar-content {
  display: flex;
  justify-content: center; /* Center text vertically */
  align-items: center; /* Center text horizontally */
  writing-mode: vertical-rl; /* Rotate text vertically */
  text-align: center; /* Align text */
  transform: rotate(180deg); /* Flip text for proper readability */
  font-size: 14px; /* Font size */
  font-family: Arial, sans-serif; /* Font family */
  color: #333; /* Text color */
  gap: 0.5rem; /* Space between lines */
  margin-top: auto;
}
.topicName {
  font-weight: bold; /* Bold text */
}
.sidebar-content .separator {
  font-size: 12px;
}
.tooltip-container {
  position: relative;
  display: flex;
  align-items: center; /* Align tooltip with the icon */
}
.tooltip-text {
  visibility: hidden;
  position: absolute;
  width: 180px;
  left: 70px; /* Position tooltip beside the sidebar */
  top: 50%; /* Align tooltip vertically with the icon */
  transform: translateY(-50%); /* Center vertically */
  background-color: #333; /* Tooltip background */
  color: #fff; /* Tooltip text color */
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  border-radius: 5px;
  padding: 5px 8px;
  z-index: 1;
  opacity: 0;
}

.click-me-tootltip {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s ease;
  width: 195px;
  padding: 10px 15px 10px 10px;
}

/* .tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  left: 70px;
} */

.close-btn {
  right: 4px;
  top: 4px;
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-btn svg {
  width: 13px;
  height: 13px;
}

.logout-tooltip {
  left: 55px !important;
  width: 70px !important;
  text-align: center;
}
.profile-tooltip {
  text-align: center;
  left: 55px !important;
  width: 120px !important;
}

@media (max-width: 1024px) {
  .sidebar-content {
    font-size: 12px;
    margin-bottom: 45px;
  }
}
</style>