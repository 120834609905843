var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[(!_vm.isTopicDetailsPage)?_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"logo-container"},[_c('img',{staticClass:"fnf-logo cursor-pointer",attrs:{"src":require('@/assets/Group 1707478419.svg') +
            '?v=' +
            new Date().getTime(),"alt":"FnF Logo"},on:{"click":_vm.homePage}})]),(!_vm.isFunTopic && !_vm.isFreeTrialOver && !_vm.isSubscriptionActive)?_c('div',{staticClass:"microphone-container tooltip-container"},[_c('button',{staticClass:"button",on:{"mouseenter":_vm.showTooltip,"click":_vm.fun}},[_c('img',{staticClass:"microphone-icon",attrs:{"src":require('@/assets/side_bar_fun.svg') +
              '?v=' +
              new Date().getTime(),"alt":"Microphone Icon"}})]),(_vm.isTooltipVisible)?_c('div',{staticClass:"tooltip-text click-me-tootltip"},[_c('button',{staticClass:"close-btn",on:{"click":_vm.closeTooltip}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"13","height":"13","viewBox":"0 0 13 13","fill":"none"}},[_c('line',{attrs:{"x1":"9.54594","y1":"3.18192","x2":"3.18198","y2":"9.54588","stroke":"white"}}),_c('line',{attrs:{"x1":"3.18204","y1":"3.18209","x2":"9.54601","y2":"9.54605","stroke":"white"}})])]),_c('span',[_vm._v(_vm._s(_vm.labels.FUN_TOOLTIP_TEXT))])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"icon-container"},[_c('button',{staticClass:"sidebar-icon button tooltip-container",on:{"click":_vm.profileMenu}},[_c('img',{attrs:{"src":require('@/assets/profile_icon.svg') +
              '?v=' +
              new Date().getTime(),"alt":"Profile Icon"}}),_c('span',{staticClass:"tooltip-text profile-tooltip"},[_vm._v(_vm._s(_vm.labels.PARENT_DASHBOARD))])]),_c('button',{staticClass:"sidebar-icon button tooltip-container",on:{"click":_vm.LogoutModal}},[_c('img',{attrs:{"src":require('@/assets/logout_icon.svg') + '?v=' + new Date().getTime(),"alt":"Logout Icon"}}),_c('span',{staticClass:"tooltip-text logout-tooltip"},[_vm._v(_vm._s(_vm.labels.LOGOUT))])])])]):_c('div',{staticClass:"topic-sidebar",class:_vm.isTopicDetailsPage ? 'topic-sidebar-width' : ''},[_c('div',{staticClass:"sidebar-content"},[_c('span',[_vm._v(_vm._s(_vm.truncateText(_vm.subjectName, 10, 15)))]),_c('span',{staticClass:"separator"},[_vm._v("|")]),_c('span',[_vm._v(_vm._s(_vm.truncateText(_vm.categoryName, 10, 15)))]),_c('span',{staticClass:"separator"},[_vm._v("|")]),_c('span',{staticClass:"topicName"},[_vm._v(_vm._s(_vm.topicName))])])]),(_vm.showLogoutModal)?_c('div',{staticClass:"sidebar-overlay"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('span',[_vm._v(_vm._s(_vm.labels.SIGN_OUT_TEXT))])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"cancel-logout",on:{"click":function($event){_vm.showLogoutModal = false}}},[_vm._v(" "+_vm._s(_vm.labels.CANCEL)+" ")]),_c('button',{staticClass:"confirm-logout",on:{"click":_vm.confirmLogout}},[_vm._v(" "+_vm._s(_vm.labels.SIGN_OUT)+" ")])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }