<template>
  <div
    ref="chartContainer"
    class="chart-container"
  >
    <!-- The container for the horizontal bar chart -->
    <svg ref="chart" />
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'HorizontalBarChart',
  props: {
    // The chartData prop expects an array of objects with the structure:
    // [{ name: 'Label', value: 50, color: '#colorCode' }, ...]
    chartData: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    // Create the bar chart after the component is mounted
    this.createBarChart();

    // Add an event listener to make the chart responsive on window resize
    window.addEventListener('resize', this.createBarChart);
  },
  beforeDestroy() {
    // Clean up the resize event listener when the component is destroyed
    window.removeEventListener('resize', this.createBarChart);
  },
  methods: {
    createBarChart() {
      // Get the container's width dynamically for responsiveness
      const containerWidth = this.$refs.chartContainer.offsetWidth;

      // Define margins for the chart
      const margin = { top: 15, right: 25, bottom: 15, left: 60 };
      const width = containerWidth - margin.left - 100; // Calculate chart width
      const height = 50 * this.chartData.length; // Calculate chart height based on the number of bars

      // Select the SVG element and set its dimensions and viewBox for scaling
      const svg = d3
        .select(this.$refs.chart)
        .attr('width', containerWidth)
        .attr('height', height + margin.top + margin.bottom)
        .attr('viewBox', `50 0 ${containerWidth} ${height + margin.top + margin.bottom}`)
        .attr('preserveAspectRatio', 'xMinYMin meet');

      // Clear any existing content in the SVG to redraw the chart
      svg.selectAll('*').remove();

      // Create a group element for chart content
      const chartGroup = svg
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      // Define scales for the x-axis (value) and y-axis (labels)
      const x = d3.scaleLinear().range([0, width]).domain([0, 100]);
      const y = d3.scaleBand().rangeRound([0, height]).padding(0.2).domain(this.chartData.map((d) => d.subject));

      // Bind data to bar groups
      const bars = chartGroup.selectAll('.bar').data(this.chartData).enter().append('g');

      // Create background bars for the full width
      bars
        .append('rect')
        .attr('class', 'background-bar')
        .attr('y', (d) => y(d.subject))
        .attr('height', y.bandwidth())
        .attr('x', 0)
        .attr('width', width)
        .attr('fill', '#FFF') // Set the background bar color to white
        .attr('rx', 18) // Rounded corners for better visuals
        .attr('ry', 18);

      // Create foreground bars for the actual values
      bars
        .append('rect')
        .attr('class', 'foreground-bar')
        .attr('y', (d) => y(d.subject))
        .attr('height', y.bandwidth())
        .attr('x', 0)
        .attr('width', (d) => x(d.percentage)) // Set width based on data value
        .attr('fill', (d) => d.color) // Set bar color dynamically
        .attr('rx', 18) // Rounded corners for better visuals
        .attr('ry', 18);

        // Add text positioned at the end of the unfilled portion of the bar
      bars
        .append('text')
        .attr('class', 'label')
        .attr('y', (d) => y(d.subject) + y.bandwidth() / 2 + 4)
        .attr('x', () => width + 30) // Position at the end of the unfilled bar
         .text((d) => {
    const truncatedSubject = d.subject.length > 7 ? `${d.subject.slice(0, 7)}...` : d.subject;
    return `${truncatedSubject} - ${d.percentage}%`;
  })
        .attr('text-anchor', 'start') // Align text to the end of the bar
        .style('font-size', '12px')

        // Emit the chart height back to the parent
    this.$emit('update-height', height);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  width: 100%; /* Make the container responsive */
}

svg {
  width: 100%; /* Scale SVG to fit container */
  height: auto; /* Maintain aspect ratio */
}

.label {
  font-size: 12px;
  fill: #000; /* Text color for labels */
}
</style>
