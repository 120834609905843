<template>
  <div class="radio-button-group">
    <button
      v-for="option in options"
      :key="option.value"
      :class="['radio-button', { selected: selectedValues.includes(option.value) }]"
      :style="{ backgroundColor: option.color }"
      @click="isEditing ? toggleSelection(option.value) : null"
    >
      <!-- Conditionally render SVGs based on selection and isEditing -->
      <span
        v-if="isEditing"
        class="radio-svg"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <circle
            cx="10"
            cy="10"
            r="10"
            fill="white"
          />
        </svg>
        <!-- Show the black circle if selected and isEditing is true -->
        <svg
          v-if="selectedValues.includes(option.value)"
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          class="inner-circle"
        >
          <circle
            cx="4"
            cy="4"
            r="4"
            fill="#323232"
          />
        </svg>
      </span>
      <!-- Always show the label -->
      {{ option.label }}
    </button>
  </div>
</template>




<script>
export default {
  name: "RadioButtonGroup",
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedValues: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    isEditing: {
        type: Boolean
    }
  },
  methods: {
  toggleSelection(value) {

    if (this.selectedValues.includes(value)) {
      // Remove if already selected
      const newSelection = this.selectedValues.filter((v) => v !== value);
      this.onChange(newSelection);
    } else {
      // Add if not selected
      const newSelection = [...this.selectedValues, value];

      // Notify parent of the new selection even if it exceeds the limit
      this.onChange(newSelection);
    }
  },

  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.radio-button-group {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.radio-button {
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  color: $text-color;
  display: flex;
  align-items: center;
  gap: 7px;
  width: 100%;
  min-height: 50px;
}

.radio-button input {
  margin: 0;
}

.custom-button-check {
  /* hiding browser el */
    appearance: none;
    /* Safari support */
    -webkit-appearance: none;
  box-shadow: 0 0 0 5px #FFF;
  border: 3px solid black;
  border-radius: 50%;
}

.custom-button-uncheck {
  /* hiding browser el */
    appearance: none;
    /* Safari support */
    -webkit-appearance: none;
  box-shadow: 0 0 0 5px #FFF;
  border: 3px solid #FFF;
  border-radius: 50%;
}

.radio-button.selected {
  font-weight: bold;
}

.radio-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.radio-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 20px;
  height: 20px;

  .inner-circle {
    position: absolute; /* Place the black circle on top of the white circle */
    top: 6px; /* Adjust as needed to center */
    left: 6px; /* Adjust as needed to center */
  }
}
@media (max-width: 800px) {
    .radio-button {
 min-width: 125px;
  min-height: 42px;
    }
}
</style>
