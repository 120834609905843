import Vue from "vue";
import VueRouter from "vue-router";
import FunTopicComponent from "@/components/FunTopicComponent";
import TopicDetailsComponent from "@/components/TopicDetailsComponent";
import LoginComponent from "@/components/LoginComponent.vue";
import RegisterComponent from "@/components/RegisterComponent.vue";
import GoogleRegisterComponent from "@/components/GoogleRegisterComponent.vue";
import TopicPage from "@/components/TopicComponent.vue";
import Terms from "@/components/TermAndCondition.vue";
import Privacy from "@/components/PrivacyPolicy.vue";
import ParentOnboardComponent from "@/components/ParentOnboardComponent";
import HomePage from "@/components/HomePage";
import ParentDashboardComponent from "@/components/ParentDashboard/ParentDashboardComponent";
import ForgotPasscodeComponent from "@/components/ParentPasscode/ForgotPasscodeComponent.vue";
import SetupPasscodeComponent from "@/components/ParentPasscode/SetupPasscodeComponent.vue";
import AdminPasscodeComponent from "@/components/ParentPasscode/AdminPasscodeComponent.vue";
import SummaryComponent from "@/components/ParentDashboard/SummaryComponent";
import SettingsComponent from "@/components/ParentDashboard/SettingsComponent";
import ProfileComponent from "@/components/ParentDashboard/ProfileComponent";
import HelpComponent from "@/components/ParentDashboard/HelpComponent";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LoginComponent,
  },
  {
    path: "/",
    name: "Login",
    component: LoginComponent,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterComponent,
  },
  {
    path: "/gisregister",
    name: "GoogleRegisterComponent",
    component: GoogleRegisterComponent,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/topic",
    name: "Topic",
    component: TopicPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/topic_details",
    name: "topicDetails",
    component: TopicDetailsComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/fun",
    name: "Fun",
    component: FunTopicComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/parent_onboard",
    name: "ParentOnboardComponent",
    component: ParentOnboardComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/parent_dashboard",
    name: "ParentDashboardComponent",
    component: ParentDashboardComponent,
    children: [
      {
        path: "summary",
        name: "summary",
        component: SummaryComponent,
      },
      {
        path: "settings",
        name: "settings",
        component: SettingsComponent,
      },
      {
        path: "profile",
        name: "profile",
        component: ProfileComponent,
      },
      {
        path: "help",
        name: "help",
        component: HelpComponent,
      },
      {
        path: "",
        redirect: { name: "summary" }, // Default route to summary
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/forgot_passcode",
    name: "ForgotPasscodeComponent",
    component: ForgotPasscodeComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/setup_passcode",
    name: "SetupPasscodeComponent",
    component: SetupPasscodeComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin_passcode",
    name: "AdminPasscodeComponent",
    component: AdminPasscodeComponent,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let hideNavbar = false;

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const token = localStorage.getItem("token");
  const isTrialOver = localStorage.getItem("trial_expired") === "true";
  const isSubscriptionActive =
    localStorage.getItem("subscription_expired") === "true";
  const isAdminValidated = localStorage.getItem("isAdminValidated");
  const isOnbording = localStorage.getItem("onboard_flag") === "true";
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  // Redirect to login if not logged in
  if (to.path === "/setup_passcode") {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      // Store the token temporarily (e.g., in a variable or Vuex)
      localStorage.setItem("setup_passcode_token", token);
    }

    // Redirect to login if the user isn't logged in
    if (!isLoggedIn) {
      next("/login"); // Redirect to login
    } else {
      next(); // Proceed to /setup_passcode
    }
  } else {
    next(); // Proceed as normal
  }
  // Allow full access if admin is validated
  if (isAdminValidated) {
    next(); // Allow navigation to the requested route
    return;
  }
  // Restrict navigation if onboarding is false
  if (isOnbording === false) {
    // Check if trial is over or subscription is active
    if (isTrialOver || isSubscriptionActive) {
      // Redirect to home for all paths except allowed ones
      if (to.path !== "/home" && !to.path.startsWith("/parent_dashboard") && !to.path.startsWith("/admin_passcode") && !to.path.startsWith("/forgot_passcode") && !to.path.startsWith("/setup_passcode")) {
        next("/home");
        return;
      }
    }
  }


  // Handle back button navigation for specific route
  if (from.path === "/parent_onboard" && to.path !== "/home") {
    next(false); // Block navigation
  } else {
    next(); // Allow navigation
  }

  if (requiresAuth && !token) {
    next("/login");
  } else if (
    (to.name === "Login" ||
      to.name === "Register" ||
      to.name === "GoogleRegisterComponent") &&
    token
  ) {
    next("/home");
  } else {
    if (
      to.name === "Login" ||
      to.name === "Register" ||
      to.name === "GoogleRegisterComponent"
    ) {
      hideNavbar = true;
    } else {
      hideNavbar = false;
    }
    next();
  }
});

export { router, hideNavbar };
