// labels.js
export const LABELS = {
    FINISH_SETUP: {
        FINISH_SETUP_TEXT: "is ready to start learning.",
        FINISH_SETUP_DESCRIPTION: "To achieve an optimum experience for your child. Guide them on how to use the app for the first time.",
        FINISH_SETUP_BUTTON: "Start your 14 day trial",
        FINISH_SETUP_PAYMENT_TEXT: "$14.99 per month after 14 day trial."
    },
    DEFAULT_COLOR: {
        BASE_COLOR: "#7DD9CC",
        COLOR_1: "#9EF9EC",
        COLOR_2: "#E1FFFB"
    },
    AUTO_LOGOUT_TIME: 30 * 60 * 1000, // 30 minutes
    ORIENTATION_DETECTION_TEXT: "Don't forget to rotate your phone to proceed",
    FUN_TOOLTIP_TEXT: "To learn and explore. Whether it’s about dragons, oceans, or your favorite food – the answers are waiting for you!",
    PARENT_DASHBOARD: "Parent Dashboard",
    LOGIN: "Login",
    LOGOUT: "Logout",
    SIGN_OUT_TEXT: "Are you sure you want to sign out?",
    CANCEL: "Cancel",
    SIGN_UP: "Sign up",
    SIGN_IN: "Sign in",
    SIGN_OUT: "Sign out",
    SIGN_IN_WITH_GOOGLE: "Sign in with Google",
    SIGN_IN_TO_YOUR_ACCOUNT: "Sign in to your account",
    HOME: "Home",
    NO_DATA_FOUND: "No Data Found",
    PAUSE: "Pause",
    PLAY: "Play",
    MICROPHONE_ACCESS: "We need access to your microphone.",
    MICROPHONE_ACCESS_TEXT: "Please give access to your microphone and refresh the page.",
    WELCOME_TO_FLUTE_N_FEATHER: "Welcome to flute n feather",
    YOUR_CHILDS_PERSONAL_COMPANION: "Your child’s personal companion",
    CHILDS_OR_TEACHERS_NAME: "Child’s or Teacher’s Full Name",
    CHILDS_AGE: "Child’s Age or Approx. Average Age of Child in Class",
    CHILDS_GENDER: "Child's Gender (For Parents Only) (Optional)",
    EMAIL: "Email Address",
    PHONE_NUMBER: "Phone number(Optional)",
    AGREE_TO_FNF: "I agree to the Flute n feather’s",
    TERMS_N_CONDITION: "Terms & Conditions",
    PRIVACY_POLICY: "Privacy Policy",
    ALREADY_HAVE_ACCOUNT: "Already have an account? Click here to",
    DONOT_HAVE_ACCOUNT: "Don't have an account? Click here to",
    PASSWORD: "Password",
    CHOOSE_PASSWORD: "Choose password",
    CONFIRM_PASSWORD: "Confirm password",
    PAYMENT_DETAILS: "Membership/Payment details",
    MEMBERSHIP_START_DATE: "Membership start date",
    MEMBERSHIP_RENEWAL_DATE: "Membership renewal date",
    TRIAL_SESSION_OVER: "Your trial session is over",
    SUBSCRIPTION_EXPIRED: "Your subscription has expired",
    SUBSCRIPTION_VALID: "Your subscription is still valid.",
    CURRENT_SUBJECT_SELECTION: "Current subject selections",
    CURRENT_CATEGORY_SELECTION: "Current category selections",
    FORGOT_PASSCODE: "Forgot passcode",
    GET_STARTED: "Get Started"
  };
  