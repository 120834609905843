<template>
  <div
    v-if="isPortrait"
    class="row m-0 overlay"
  >
    <div class="col-12 text-center">
      <img
        src="../../assets/OrientationDetector.png"
        class="orientation-image"
      />
      <div class="overlay-text">
        {{ labels.ORIENTATION_DETECTION_TEXT }}
      </div>
    </div>
  </div>
</template>

<script>
import { LABELS } from "@/constants/labels";
export default {
  name: 'OrientationDetector',
  data() {
    return {
      isPortrait: false,
      pausedVideoMedia: false,
      labels: LABELS,
    };
  },
  mounted() {
    this.handleOrientationChange();
    window.addEventListener("resize", this.handleOrientationChange);
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleOrientationChange);
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  },
  methods: {
    handleOrientationChange() {
      this.isPortrait = window.matchMedia("(orientation: portrait)").matches;
      this.$emit('update-orientation', this.isPortrait);  // Emit updated orientation status

      if (this.isPortrait) {
        this.$emit('pause-llm-audio'); // Emit event to parent component to pause media
        this.$emit('pause-presentation-audio'); // Emit event to pause presentation audio
        this.$emit('pause-fun-audio');
        this.pauseVideoMedia();
      } else {
        this.$emit('resume-llm-audio'); // Emit event to parent component to resume media
        this.$emit('resume-presentation-audio'); // Emit event to resume presentation audio
        this.$emit('resume-fun-audio');
        this.resumeVideoMedia();
      }
    },
    handleVisibilityChange() {
      if (!document.hidden) {
        // Re-check orientation when returning to the page
        this.handleOrientationChange();
      }
    },
    pauseVideoMedia() {
      const mediaElements = document.querySelectorAll("video, audio");
      mediaElements.forEach((media) => {
        if (!media.paused) {
          media.pause();
          this.pausedVideoMedia = true;
        }
      });
    },
    resumeVideoMedia() {
      if (this.pausedVideoMedia) {
        const mediaElements = document.querySelectorAll("video, audio");
        mediaElements.forEach((media) => {
          media.play();
        });
        this.pausedVideoMedia = false;
      }
    },
  }
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F5F6F0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  z-index: 99999999;
}

.orientation-image {
  margin-bottom: 50px; /* Adjust this value as needed to control spacing */
}

.overlay-text {
  background-color: #EDE6CF; /* Example background color */
  padding: 10px; /* Add some padding around the text */
  border-radius: 5px; /* Optional: rounded corners for better appearance */
  color: #000000; /* Text color */
  text-align: left; /* Center text */
  font-size: 24px;
  font-weight: 600;
}

</style>
