<template>
  <div class="container-fluid container-min-height scroll-top">
    <!-- Loop through subjects -->
    <div
      v-if="!isLoading && subjectArr.length > 0"
      class="row m-0 home-padding"
    >
      <div class="col-12 col-sm-12 col-md-12 col-xl-12 p-0">
        <div
          v-for="(subject, index) in subjectArr"
          :key="index"
          class="row m-0 mb-3"
        >
          <div
            class="col-12 col-sm-12 col-md-12 col-xl-12 p-0 d-flex subject-card"
            :style="{
              backgroundColor:
                subject.base_color || labels.DEFAULT_COLOR.BASE_COLOR,
            }"
          >
            <div class="vertical-text fw-600">
              {{ subject.subject_name }}
            </div>
            <div class="vertical-line"></div>
            <div
              ref="topicListHeight"
              class="row m-0 row-min-height topic-list-container"
            >
              <!-- scroll icon -->
              <div
                v-if="windowWidth > 1000"
                class="col-12 arrow-position"
              >
                <!-- Left scroll icon -->
                <svg
                  v-if="subject.list_topics.length >= 4"
                  class="svg-arrow-icon"
                  :class="{ disabled: !canScrollLeft[index] }"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                  stroke-linejoin="round"
                  stroke-miterlimit="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="scrollLeft(index)"
                >
                  <path
                    d="m12.017 1.995c5.517 0 9.997 4.48 9.997 9.998s-4.48 9.998-9.997 9.998c-5.518 0-9.998-4.48-9.998-9.998s4.48-9.998 9.998-9.998zm0 1.5c-4.69 0-8.498 3.808-8.498 8.498s3.808 8.498 8.498 8.498 8.497-3.808 8.497-8.498-3.807-8.498-8.497-8.498zm-1.528 4.715s-1.502 1.505-3.255 3.259c-.147.147-.22.339-.22.531s.073.383.22.53c1.753 1.754 3.254 3.258 3.254 3.258.145.145.335.217.526.217.192-.001.384-.074.531-.221.292-.293.294-.766.003-1.057l-1.977-1.977h6.693c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-6.693l1.978-1.979c.29-.289.287-.762-.006-1.054-.147-.147-.339-.221-.53-.222-.19 0-.38.071-.524.215z"
                    fill-rule="nonzero"
                  />
                </svg>
                <!-- Right scroll icon -->
                <svg
                  v-if="subject.list_topics.length >= 4"
                  class="svg-arrow-icon pl-20"
                  :class="{ disabled: !canScrollRight[index] }"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                  stroke-linejoin="round"
                  stroke-miterlimit="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="scrollRight(index)"
                >
                  <path
                    d="m12.012 1.995c-5.518 0-9.998 4.48-9.998 9.998s4.48 9.998 9.998 9.998 9.997-4.48 9.997-9.998-4.479-9.998-9.997-9.998zm0 1.5c4.69 0 8.497 3.808 8.497 8.498s-3.807 8.498-8.497 8.498-8.498-3.808-8.498-8.498 3.808-8.498 8.498-8.498zm1.528 4.715s1.502 1.505 3.255 3.259c.146.147.219.339.219.531s-.073.383-.219.53c-1.753 1.754-3.254 3.258-3.254 3.258-.145.145-.336.217-.527.217-.191-.001-.383-.074-.53-.221-.293-.293-.295-.766-.004-1.057l1.978-1.977h-6.694c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h6.694l-1.979-1.979c-.289-.289-.286-.762.006-1.054.147-.147.339-.221.531-.222.19 0 .38.071.524.215z"
                    fill-rule="nonzero"
                  />
                </svg>
              </div>
              <div
                :ref="'topicListContainer' + index"
                class="topic-list"
                :class="windowWidth > 1000 ? 'pt-20' : ''"
                @scroll="handleScroll(index)"
              >
                <div
                  v-for="(topic, tIndex) in subject.list_topics"
                  :key="tIndex"
                  class="topic-tiles"
                >
                  <div
                    class="card card-radius cursor-pointer"
                    @click="selectTopic(subject, topic)"
                  >
                    <img
                      class="card-img-top"
                      :src="topic.thumbnail_image"
                      alt="Card image"
                    />
                    <div
                      class="font-size-14 fw-400 text-position"
                      :style="{
                        backgroundColor:
                          subject.color_1 || labels.DEFAULT_COLOR.COLOR_1,
                      }"
                    >
                      {{ topic.category_name }}
                    </div>
                    <div
                      class="card-body card-body-down mt-5 d-flex align-items-start"
                      :style="{
                        backgroundColor:
                          subject.color_2 || labels.DEFAULT_COLOR.COLOR_2,
                      }"
                    >
                      <div class="col-12 p-0 d-flex align-items-start">
                        <div class="col-12 p-0 card-button-text">
                          <span
                            class="font-size-16 m-0 text-capitalize text-color"
                          >{{ topic.topic_name }}</span>
                        </div>
                        <!-- <div class="col-4 p-0 mb-2">
                          <span class="font-size-10"
                            >Progress({{
                              progressText(topic.topic_status)
                            }})</span
                          >
                          <div class="progress bg-white h-5">
                            <div
                              class="progress-bar"
                              role="progressbar"
                              :style="{
                                width: progressBarWidth(topic.topic_status),
                                backgroundColor:
                                  subject.base_color + ' !important',
                              }"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <span class="sr-only">{{
                                progressBarWidth(topic.topic_status)
                              }}</span>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isFreeTrialOver || isSubscriptionActive"
      class="modal-overlay"
    >
      <div class="modal-outer-layout">
        <div class="modal-content">
          <!-- Close Button -->
          <!-- <button class="modal-close-btn" @click="closeModal">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.302 7.69809C17.1751 7.57125 17.0031 7.5 16.8237 7.5C16.6442 7.5 16.4722 7.57125 16.3453 7.69809L12.0876 11.9558L7.82997 7.69809C7.70237 7.57485 7.53146 7.50665 7.35407 7.50819C7.17667 7.50974 7.00698 7.58089 6.88154 7.70633C6.7561 7.83178 6.68494 8.00147 6.6834 8.17886C6.68186 8.35626 6.75005 8.52716 6.8733 8.65477L11.131 12.9124L6.8733 17.1701C6.80868 17.2325 6.75714 17.3072 6.72168 17.3897C6.68622 17.4723 6.66755 17.561 6.66677 17.6509C6.66599 17.7407 6.68311 17.8298 6.71713 17.913C6.75115 17.9961 6.80139 18.0716 6.86491 18.1352C6.92844 18.1987 7.00398 18.2489 7.08713 18.283C7.17027 18.317 7.25937 18.3341 7.3492 18.3333C7.43903 18.3325 7.52781 18.3139 7.61036 18.2784C7.6929 18.2429 7.76756 18.1914 7.82997 18.1268L12.0876 13.8691L16.3453 18.1268C16.4729 18.25 16.6438 18.3182 16.8212 18.3167C16.9986 18.3151 17.1683 18.244 17.2937 18.1185C17.4192 17.9931 17.4903 17.8234 17.4919 17.646C17.4934 17.4686 17.4252 17.2977 17.302 17.1701L13.0443 12.9124L17.302 8.65477C17.4288 8.52789 17.5001 8.35583 17.5001 8.17643C17.5001 7.99703 17.4288 7.82497 17.302 7.69809Z"
                fill="#323232"
              />
              <circle
                cx="12.5"
                cy="12.5"
                r="11.75"
                stroke="#323232"
                stroke-width="1.5"
              />
            </svg>
          </button> -->
          <div class="modal-title">
            <p>Time to nudge your parents</p>
          </div>

          <!-- Peacock Image -->
          <img
            src="../assets/FeatherCharacter.png"
            alt="Peacock Character"
            class="modal-image"
          />

          <div
            v-if="isSubscriptionEnds"
            class="modal-body-text"
          >
            <p>
              Your subscription has expired. Please renew it to keep using the
              app.
            </p>
          </div>
          <div
            v-else
            class="modal-body-text"
          >
            <p>
              Hope you enjoyed the 14 day trial. To continue learning upgrade to
              the paid version
            </p>
          </div>

          <!-- Upgrade Button -->
          <button
            class="modal-upgrade-btn"
            @click="redirectToProfile"
          >
            Update your payment method
          </button>
          <div class="row m-0 d-flex align-items-end have-code">
            <div
              class="ml-auto font-size-14 cursor-pointer"
              @click="adminRedirect"
            >
              <u>Have a code</u>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading
      :active="isLoading"
      color="#9651F1"
      background-color="#C9C9C9"
      :height="100"
      :width="100"
    />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import { updateTitle } from "@/utils/utils";
import { LABELS } from "@/constants/labels";
import { globalState } from "@/App.vue";
export default {
  name: "HomePage",
  components: { Loading },
  data() {
    return {
      subjectArr: [],
      canScrollLeft: {}, // Keep track of scroll state for each subject
      canScrollRight: {},
      isLoading: false,
      preloadedCharacterData: {}, // Store preloaded character data here
      labels: LABELS,
      isFreeTrialOver: false, // Set this flag based on your condition
      isSubscriptionEnds: false,
      isSubscriptionActive: false,
      showSuccessModal: false,
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    isFreeTrialOver(newValue) {
      globalState.isModalVisible = newValue; // Update global state
    },
    isSubscriptionActive(newValue) {
      globalState.isModalVisible = newValue;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
    // Check for payment success flag
    if (localStorage.getItem("paymentSuccess") === "true") {
      this.showSuccessModal = true;

      // Clear the flag
      localStorage.removeItem("paymentSuccess");

      // Auto-hide the modal after 3 seconds
      setTimeout(() => {
        this.showSuccessModal = false;
      }, 5000);
    }
    localStorage.removeItem("isAuthenticated");
    this.checkTrialStatus();
    this.fetchAndCacheCharacterData(); // Preload in the background
    updateTitle("Home");
    // const token = localStorage.getItem("token");
    // const loaded = localStorage.getItem("pageReloaded");
    // this.loggedIn = !!token;
    // if (this.$route.path === "/home" && token && !loaded) {
    //   this.$notify({
    //     group: "foo",
    //     type: "success",
    //     text: "Succesfully Logged In",
    //   });
    //   localStorage.setItem("pageReloaded", "loaded");
    // }
    this.fetchTopic();
    this.$store.dispatch("fetchSubjects");
    this.setEqualVerticalTextWidth();
    //    this.$nextTick(() => {
    //   this.setEqualCardHeights();
    //   this.setEqualVerticalTextWidth();
    // });
    window.addEventListener("resize", this.setEqualCardHeights);
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("orientationchange", this.handleOrientationChange);
    // Push an initial state to the history stack
    history.pushState(null, null, location.href);

    // Prevent back navigation
    window.addEventListener("popstate", this.preventBackNavigation);
  },
  beforeDestroy() {
    // Clean up event listener on component destroy
    window.removeEventListener("resize", this.setEqualCardHeights);
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.updateWindowWidth);
    window.removeEventListener(
      "orientationchange",
      this.handleOrientationChange
    );
    // Restrict back button navigation
    history.pushState(null, null, location.href);
    window.addEventListener("popstate", this.preventBackNavigation);

    // Continuously push state to block multiple back clicks
    // Clear the interval to avoid memory leaks
    clearInterval(this.blockBackButtonInterval);
  },

  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    adminRedirect() {
      this.$router.push("/admin_passcode");
    },
    checkTrialStatus() {
      const trialOver = localStorage.getItem("trial_expired") === "true";
      // const subscritionExpired = localStorage.getItem("subscription_expiry_flag") === "true";
      // this.isSubscriptionEnds = subscritionExpired;
      const isSubscriptionActive =
        localStorage.getItem("subscription_expired") === "true";
      this.isSubscriptionActive = isSubscriptionActive;
      this.isSubscriptionEnds = isSubscriptionActive;
      // if(this.isSubscriptionActive === true){
      //   localStorage.removeItem("subscription_is_valid");
      // }
      const isAdminValidated =
        localStorage.getItem("isAdminValidated") === "true";

      // If the user is an admin and has validated the passcode, do not show the modal
      if (isAdminValidated) {
        this.isFreeTrialOver = false;
      } else {
        this.isFreeTrialOver = trialOver;
      }
    },
    closeModal() {
      this.isFreeTrialOver = false;
    },
    cancelPopup() {
      this.showSuccessModal = false;
    },
    redirectToProfile() {
      // Redirect user to the payment page
      window.location.href = "/parent_dashboard/profile";
    },
    async fetchAndCacheCharacterData() {
      try {
        const response = await axios.get(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_GET_CHARACTER_DATA
        );

        if (response && response.data) {
          const { json_data, png_data } = response.data;

          // Open a cache storage instance
          const cache = await caches.open("character-data-cache");

          // Preload JSON and PNG data into the cache
          const preloadPromises = [];

          for (const character in json_data) {
            for (const action in json_data[character]) {
              const jsonUrl = json_data[character][action];
              const pngUrl = png_data[character][action];

              preloadPromises.push(
                Promise.all([
                  this.fetchPoseJson(jsonUrl).then((spriteData) => {
                    if (spriteData) {
                      cache.put(
                        jsonUrl,
                        new Response(JSON.stringify(spriteData))
                      );
                    }
                  }),
                  this.preloadImage(pngUrl).then((image) => {
                    if (image) {
                      cache.put(pngUrl, new Response(image.src));
                    }
                  }),
                ])
              );
            }
          }

          await Promise.all(preloadPromises);

        }
      } catch (error) {
        console.error("Error fetching and caching character data:", error);
      }
    },
    async fetchPoseJson(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch JSON: ${response.statusText}`);
        }
        return await response.json();
      } catch (error) {
        console.error("Error fetching JSON:", error);
        return null;
      }
    },
    async preloadImage(imageUrl) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => resolve(img);
        img.onerror = reject;
      });
    },
    preventBackNavigation() {
      // Push the current state to the history stack to keep the user on the same page
      history.pushState(null, null, location.href);
    },
    fetchTopic() {
      this.isLoading = true;
      let dataTosend = {};
      (dataTosend.user_email = localStorage.getItem("email")),
        // (dataTosend.subject_name = this.subjectName),
        axios
          .post(
            process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
              process.env.VUE_APP_FLUTE_N_FEATHER_TOPIC_URL_PATH,
            dataTosend
          )
          .then((response) => {
            this.subjectArr = response.data || [];
            if (this.subjectArr) {
              this.$nextTick(() => {
                this.setEqualVerticalTextWidth();
                setTimeout(() => {
                  this.setEqualCardHeights();
                  this.subjectArr.forEach((_, index) => {
                  this.updateScrollState(index);
                });
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }, 100);
              });
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error("Error fetching subject", error);
          });
    },
    selectTopic(subject, topic) {
      // Find the subject_name associated with the selected topic
      const selectedSubject = this.subjectArr.find((subject) =>
        subject.list_topics.some((t) => t.topic_name === topic.topic_name)
      );

      this.$store.dispatch("updateSubjectName", selectedSubject.subject_name);
      this.$store.dispatch("updateCategoryName", topic.category_name);
      this.$store.dispatch("updateTopicName", topic.topic_name);

      // Save topic details to localStorage
      localStorage.setItem("topicDetails", JSON.stringify(topic));
      localStorage.setItem("topicName", topic.topic_name);
      localStorage.setItem("videoTitle", topic.video_title);
      localStorage.setItem("categoryName", topic.category_name);
      const topicBaseColor =
        subject.base_color && subject.base_color.trim() !== ""
          ? subject.base_color
          : this.labels.DEFAULT_COLOR.BASE_COLOR;
      const topicSecondarColor =
        subject.color_1 && subject.color_1.trim() !== ""
          ? subject.color_1
          : this.labels.DEFAULT_COLOR.COLOR_1;
      const topicTertiaryColor =
        subject.color_2 && subject.color_2.trim() !== ""
          ? subject.color_2
          : this.labels.DEFAULT_COLOR.COLOR_2;
      localStorage.setItem("topicColor", topicBaseColor);
      localStorage.setItem("topicSecondarColor", topicSecondarColor);
      localStorage.setItem("topicTertiaryColor", topicTertiaryColor);

      // Save the associated subject_name to localStorage
      if (selectedSubject) {
        localStorage.setItem("subjectName", selectedSubject.subject_name);
      }

      // Enable NoSleep (to keep the screen awake)
      if (this.noSleep) {
        this.noSleep.enable();
      }
      // navigate to a route with the topic name as a parameter
      this.$router.push({
        name: "topicDetails",
        query: { topic: topic.topic_name },
      });
    },
    progressBarWidth(status) {
      if (status === "Not Completed") {
        return "0%";
      } else if (status === "In Progress") {
        return "50%";
      } else if (status === "completed") {
        return "100%";
      } else {
        // Default value if status is not recognized
        return "0%";
      }
    },
    progressText(status) {
      if (status === "Not Completed") {
        return "0%";
      } else if (status === "In Progress") {
        return "50%";
      } else if (status === "completed") {
        return "100%";
      } else {
        return "0%"; // Default text if status is unrecognized
      }
    },
    scrollLeft(index) {
      const container = this.getContainerElement(index);
      if (container) {
        container.scrollBy({ left: -300, behavior: "smooth" });
        setTimeout(() => this.updateScrollState(index), 300); // Ensure the state updates after scrolling
      }
    },
    scrollRight(index) {
      const container = this.getContainerElement(index);
      if (container) {
        container.scrollBy({ left: 300, behavior: "smooth" });
        setTimeout(() => this.updateScrollState(index), 300);
      }
    },
    handleScroll(index) {
      this.updateScrollState(index); // Check scroll position dynamically
    },
    updateScrollState(index) {
      const container = this.getContainerElement(index);
      if (container) {
        const canScrollLeft = container.scrollLeft > 0;
        const canScrollRight =
          container.scrollWidth > container.clientWidth + container.scrollLeft;

        this.$set(this.canScrollLeft, index, canScrollLeft);
        this.$set(this.canScrollRight, index, canScrollRight);
      }
    },
    getContainerElement(index) {
      // Access the specific container by index
      const refArray = this.$refs["topicListContainer" + index];
      // Ensure we have an element (it could be an array if used in v-for)
      return Array.isArray(refArray) ? refArray[0] : refArray;
    },
    setEqualCardHeights() {
      // Select all rows
      const rows = this.$refs.topicListHeight;
      if (!rows || rows.length === 0) {
        return;
      }

      rows.forEach((row) => {
        // Get all cards in the current row
        const cards = row.querySelectorAll(".topic-tiles .card");
        if (cards.length === 0) {
          return;
        }

        // Reset height to auto to handle updates
        cards.forEach((card) => (card.style.height = "auto"));

        // Find the maximum height
        const maxHeight = Array.from(cards).reduce((max, card) => {
          return Math.max(max, card.offsetHeight);
        }, 0);
        // Apply the maximum height to all cards
        cards.forEach((card) => {
          card.style.height = `${maxHeight}px`;
        });
      });
    },
    handleOrientationChange() {
      // Trigger layout recalculation when orientation changes
      setTimeout(() => {
        this.setEqualVerticalTextWidth();
      }, 100); // Adjust the timeout duration if needed
    },
    handleResize() {
      // Delayed layout recalculation to allow browser time to adjust styles
      setTimeout(() => {
        this.setEqualVerticalTextWidth();
      }, 100); // Adjust the timeout duration if needed
    },
    setEqualVerticalTextWidth() {
      this.$nextTick(() => {
        // Select all .vertical-text elements
        const verticalTexts = Array.from(
          document.querySelectorAll(".vertical-text")
        );
        const topicTilesContainers = Array.from(
          document.querySelectorAll(".topic-list")
        );

        if (!verticalTexts.length || !topicTilesContainers.length) {
          return;
        }

        // Reset styles to allow recalculating correct sizes
        verticalTexts.forEach((text) => {
          text.style.width = "auto";
          text.style.height = "auto";
        });

        // Calculate the maximum width required for vertical texts
        const maxWidth = verticalTexts.reduce(
          (max, text) => Math.max(max, text.scrollWidth),
          0
        );

        // Apply the calculated max width to all vertical texts
        verticalTexts.forEach((text) => {
          const screenWidth = window.innerWidth;
          if (screenWidth < 1000) {
            text.style.width = `${maxWidth + 15}px`; // Slight buffer for padding
          } else {
            text.style.width = `${maxWidth}px`;
          }
        });

        // Adjust the height of each subject card to align with the tallest topic tiles container
        topicTilesContainers.forEach((container, index) => {
          const cards = Array.from(
            container.querySelectorAll(".topic-tiles .card")
          );

          if (!cards.length) {
            console.warn(`No cards found in container at index ${index}`);
            return;
          }

          // Find the maximum height of cards in the current container
          const maxCardHeight = cards.reduce(
            (max, card) => Math.max(max, card.offsetHeight),
            0
          );

          // Determine additional height adjustments based on screen width
          const screenWidth = window.innerWidth;
          const extraHeight = screenWidth < 1000 ? 20 : 70;

          // Apply calculated height to the corresponding subject card
          const subjectCard = verticalTexts[index]?.closest(".subject-card");
          if (subjectCard) {
            subjectCard.style.height = `${maxCardHeight + extraHeight}px`;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  position: relative; /* Ensure the close button is positioned relative to this container */
  background: var(
    --intro-gradient-1,
    linear-gradient(0deg, #fff8e2 0.71%, #fff1c3 56.33%, #ffeaa6 99.97%)
  );
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  max-width: 400px; /* Limit the popup width */
  width: 90%; /* Make it responsive */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 170px;
}
.success-title {
  color: #383838;
  font-family: Arial;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  margin-left: 115px;
  margin-top: 47px;
  text-align: center;
}

.popup-actions {
  margin-top: 20px;
}

.popup-actions button {
  margin: 0 10px;
  margin-top: 24px;
}
.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.modal-close-btn svg {
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease;
}

.modal-close-btn:hover svg {
  transform: scale(1.1);
}

.modal-success-image {
  width: 156px;
  position: absolute;
  left: 0;
}

.cancel-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  background: var(
    --intro-gradient-1,
    linear-gradient(0deg, #f7f1dd 0.71%, #f1e6bf 56.33%, #ffe89d 99.97%)
  );
}

.have-code {
  position: relative;
  left: -8px;
  bottom: -8px;
}
.home-padding {
  padding: 25px 15px;
  width: 100%;
}
@media (max-width: 1000px) {
  .home-page {
    padding: 15px 0px;
  }
}
.scroll-top {
  overflow-y: auto;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

/* Outer Layout */
.modal-outer-layout {
  width: 877px;
  height: 484px;
  flex-shrink: 0;
  border-radius: 15px;
  background: var(
    --intro-gradient-1,
    linear-gradient(0deg, #fff8e2 0.71%, #fff1c3 56.33%, #ffeaa6 99.97%)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}

@media (max-width: 1024px) {
  .modal-outer-layout {
    width: 621px;
    height: 302px;
  }
}

/* Overlay */
.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
}

/* Modal Content */
.modal-content {
  justify-content: center;
  width: 796px;
  height: 422px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #fff;
  border: none;
}

@media (max-width: 1024px) {
  .modal-content {
    width: 570.769px;
    height: 263.73px;
  }
}

.modal-close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  border: none;
  background: none;
  cursor: pointer;
}

.modal-title {
  color: #383838;
  font-family: Arial;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin-left: -20px;
  text-align: center;
}

.modal-image {
  width: 266px;
}

.modal-body-text {
  width: 405px;
  color: #383838;
  text-align: center;
  text-align: left; /* Ensures text aligns from the left */
  margin: 0; /* Removes any default margins */
  padding: 0; /* Removes padding if present */
  /* H2 - DESKTOP */
  font-family: Arial;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal; /* 120% */
}

/* Standard Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .modal-body-text {
    margin-left: 265px;
    margin-top: -168px;
  }

  .modal-title {
    margin-bottom: -80px;
  }
}

/* Ultra-Wide Screens (Above 1920px) */
@media (min-width: 1921px) {
  .modal-body-text {
    margin-left: 266px;
    margin-top: -171px;
  }

  .modal-title {
    margin-bottom: -74px;
  }
}

/* Large Desktops (1441px to 1920px) */
@media (min-width: 1441px) and (max-width: 1920px) {
  .modal-body-text {
    margin-left: 263px;
    margin-top: -164px;
  }

  .modal-title {
    margin-bottom: -74px;
  }
}

/* Large Tablets (768px to 1024px) */
@media (max-width: 1024px) {
  .modal-outer-layout {
    width: 620px;
    height: 307px;
  }
  .modal-content {
    width: 561.769px;
    height: 273.73px;
    margin-right: -6px;
    margin-top: 3px;
  }
  .modal-body-text {
    width: 302px;
    font-size: 22px;
    margin-top: -100px;
    margin-left: 180px;
    letter-spacing: -0.44px;
  }

  .modal-title {
    font-size: 17px;
    position: absolute;
    top: 40px;
    right: 177px;
    line-height: 28px;
  }

  .modal-image {
    width: 186px;
    height: 156px;
  }
}

.modal-upgrade-btn {
  width: 744px;
  height: 50px;
  flex-shrink: 0;
  background-color: #ede7d2;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: #323232;
  text-align: center;
  margin-left: 25px;
  margin-top: 30px;
  /* SM MOBILE - BODY */
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
}
@media (max-width: 1024px) {
  .modal-upgrade-btn {
    width: 515px;
    height: 42px;
  }
}
.subject-card {
  border-radius: 15px;
  min-height: 200px;
  // max-height: 340px
}

.vertical-text {
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  min-width: 60px;
  overflow-wrap: break-word; /* Break long words */
  word-wrap: break-word; /* For older browsers */
  white-space: normal; /* Allow wrapping */
  // height: 340px;
  // white-space: normal;
  /* left: 150px; */
  // word-break: break-all;
  top: 0;
  /* bottom: 30px; */
  font-size: 20px;
  position: relative;
  text-align: inherit;
  line-height: normal;
  padding: 25px 10px;
  font-family: Arial;
  color: $text-color;
  display: flex;
  align-items: center;
}

.vertical-line {
  border-left: 2px solid #ffffff;
}

// .row-min-height {
//   min-height: 200px;
//   padding: 15px;
// }

.topic-list-container {
  overflow-x: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;
  display: flex;
  gap: 20px;
  padding: 25px 27px; /* Give space for icons */
  position: relative;
  width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.topic-list-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.topic-list-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.topic-list {
  display: flex;
  gap: 25px;
  overflow-x: scroll;
  white-space: nowrap;
}

.topic-list::-webkit-scrollbar {
  display: none;
}

.topic-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.topic-tiles {
  display: inline-block;
}

.topic-tiles .card {
  transition: height 0.2s ease-in-out; /* Smooth height adjustment */
}

/* Left and right scroll icons */
.scroll-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
  color: #333;
  padding: 5px;
  border-radius: 50%;
}

// .disabled {
//   opacity: 0.5;
//   pointer-events: none;
// }
.scroll-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.left-icon {
  left: 0;
}

.right-icon {
  right: 0;
  cursor: pointer;
}

.card-radius {
  border-radius: 15px;
  border: 5px solid #ffffff;
  background: #fff;
  height: auto;
  width: 320px;
}

.card-img-top {
  height: 225px;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}

.text-position {
  position: absolute;
  min-width: 100px;
  top: 190px;
  left: 10px;
  border-radius: 15px;
  padding: 0 10px;
  font-family: Arial;
  color: $text-color;
}

.card-body-down {
  border-radius: 0px 0px 15px 15px;
  height: 100%;
  color: $text-color;
  font-family: Arial;
  padding: 5px 10px;
}
.card-button-text {
  white-space: normal;
}
@media (min-width: 401px) and (max-width: 1000px) {
  .subject-card {
    min-height: 155px;
    // max-height: 178px
  }
  .topic-list-container {
    padding: 10px 15px;
  }
  .topic-list {
    gap: 15px;
  }
  .card-radius {
    // height: 150px;
    height: auto;
    width: 225px;
    border-radius: 5px;
    border: 2px solid #ffffff;
  }
  .card-img-top {
    height: 107px;
    border-radius: 5px 5px 0px 0px;
  }
  .vertical-text {
    // min-width: 45px;
    height: 178px;
    padding: 10px 15px;
    // left: 70px;
    // top: 30px;
  }
  .text-position {
    top: 80px;
  }
  .card-body-down {
    border-radius: 0px 0px 5px 5px;
    height: 100%;
  }
  .card-button-text {
    white-space: normal;
  }
}
.svg-arrow-icon {
  height: 25px;
  cursor: pointer;
}
.arrow-position {
  position: absolute;
  top: 10px;
  text-align: right;
  right: 10px;
}

.svg-arrow-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
@media (max-width: 1000px) {
  .svg-arrow-icon {
    display: none; /* Hide arrows on smaller screens */
  }
}
@media (min-width: 1001px) {
  .svg-arrow-icon {
    display: inline-block; /* Show arrows on desktops */
  }
}
</style>
