<template>
  <div>
    <div>
      <label :for="id">{{ label }}</label>
      <input
        :id="id"
        :name="name"
        :type="type"
        :placeholder="placeholder"
        :value="value"
        :class="['form-control', { invalid: !valid }, className]"
        :readonly="readOnly"
        :maxlength="maxLength"
        @input="handleInput"
        @change="handleChange"
      />
      <span
        v-if="!valid"
        class="error"
      >{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextField",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: String, // Define the name prop
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "", // Default CSS class for the button
    },
  },
  methods: {
    handleInput(event) {
      let inputValue = event.target.value;
      if (inputValue.length > this.maxLength) {
        inputValue = inputValue.slice(0, this.maxLength);
        event.target.value = inputValue;
      }
      this.$emit("input", event.target.value);
    },
    handleChange(event) {
      this.$emit("change", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";

label {
  display: block;
  font-weight: bold;
  margin: 0rem !important;
}

input {
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  background: $dashboard-content-bg-color;
  gap: 8px;
  color: #5e6366;
  border-radius: 10px;
}

input:disabled,
input[readonly] {
  background: $dashboard-content-bg-color !important; /* Use !important to override global styles */
  color: #5e6366; /* Ensure text remains readable */
  cursor: not-allowed; /* Optional: Indicate a non-editable field */
}
input:focus {
  border: none !important;
  box-shadow: none !important;
  background: $dashboard-content-bg-color;
}

.error {
  color: red;
  font-size: 0.8rem;
}

.invalid {
  border-color: red;
}
/* disable increase and decrease arrow keys  in textfeild */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>