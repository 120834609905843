<template>
  <div class="container-fluid finish-setup-outer-container">
    <div class="row m-0 container-min-height finish-setup-padding">
      <div
        class="col-12 col-sm-12 col-md-12 col-xl-12 bg-white finish-setup-inner-container"
      >
        <!-- onlick on profile icon we have to enter passcode to redirect to parent dashboard page  -->
        <div
          class="row"
          style="display: grid"
        >
          <h1 class="title">
            Welcome, {{ username }}'s parents
          </h1>
          <p class="subtitle">
            To access your dashboard, please enter your passcode to see your
            child’s progress or update your profile.
          </p>
          <div class="input-group">
            <input
              v-for="(item, index) in 4"
              :key="index"
              :ref="'passcodeInput' + index"
              v-model="passcode[index]"
              type="text"
              maxlength="1"
              class="passcode-input"
              inputmode="numeric"
              @keydown="moveFocus(index, $event)"
              @input="sanitizeInput(index)"
            />
          </div>
          <!-- Forgot Passcode Link -->
          <div class="forgot-passcode-container">
            <a
              href="forgot_passcode"
              class="forgot-passcode"
              @click.prevent="handleForgotPasscode"
            >{{ labels.FORGOT_PASSCODE }}</a>
          </div>

          <p
            v-if="errorMessage"
            class="text-danger"
          >
            {{ errorMessage }}
          </p>
          <div class="actions">
            <button
              class="save-passcode"
              :disabled="!isPasscodeComplete && errorMessage"
              @click="validatePasscode"
            >
              {{ labels.GET_STARTED }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <loading
      :active="isLoading"
      color="#9651F1"
      background-color="#C9C9C9"
      :height="100"
      :width="100"
    />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import { LABELS } from "@/constants/labels";

export default {
  name: "PasscodeSetup",
    components: {
    Loading
  },
  data() {
    return {
      passcode: ["", "", "", ""], // Array to hold each input value
      errorMessage: "", // Error message for invalid passcode
      isLoading: false,
      username: localStorage.getItem("username"),
      labels: LABELS,
    };
  },
  computed: {
    // Check if all 4 digits are filled
    isPasscodeComplete() {
      return this.passcode.every((digit) => digit.length === 1);
    },
  },
  mounted() {
  // Focus on the first passcode input when the component is mounted
  this.$nextTick(() => {
    if (this.$refs.passcodeInput0 && this.$refs.passcodeInput0[0]) {
      this.$refs.passcodeInput0[0].focus();
    }
  });
},
  methods: {
    sanitizeInput(index) {
      this.errorMessage = "";
      // Ensure the input is numeric and only one character
      this.passcode[index] = this.passcode[index]
        .replace(/[^0-9]/g, "")
        .slice(0, 1);

      // Automatically move focus to next input when the current one is filled
      if (
        this.passcode[index].length === 1 &&
        index < this.passcode.length - 1
      ) {
        this.focusNextInput(index);
      }
    },

    moveFocus(index, event) {
    if (event.key === "Enter" && this.isPasscodeComplete) {
      this.validatePasscode(); // Trigger the "Get Started" button's action
    } else if (event.key === "Backspace" && index > 0 && !this.passcode[index]) {
      this.focusPreviousInput(index); // Handle backspace navigation
    }
    },

    focusNextInput(index) {
      // Focus on the next input field after current one
      if (index < this.passcode.length - 1) {
        this.$nextTick(() => {
          this.$refs[`passcodeInput${index + 1}`][0].focus();
        });
      }
    },

    async handleForgotPasscode() {
      try {
        this.isLoading = true;
        const userEmail = localStorage.getItem("email");
        const payload = {
          user_email: userEmail,
        };
        // Call the API to send the password reset email
        const response = await axios.post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_REQUEST_RESET,
          payload
        );
        if (
          response.data.message === "Password reset email sent successfully"
        ) {
          this.isLoading = false;
          // Redirect to /forgot_passcode route
          this.$router.push("/forgot_passcode");
        }
      } catch (error) {
        this.isLoading = false;
      }
    },

    focusPreviousInput(index) {
      // Focus on the previous input field
      if (index > 0) {
        this.$nextTick(() => {
          this.$refs[`passcodeInput${index - 1}`][0].focus();
        });
      }
    },
    async validatePasscode() {
      const fullPasscode = this.passcode.join("");
      const payload = {
        user_email: localStorage.getItem("email"),
        passcode: fullPasscode, // Convert passcode to integer
      };

      try {
        const response = await axios.post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_VALIDATE_PASSCODE,
          payload
        );
        if (response.data.message === "Passcode validated successfully") {
          this.errorMessage = ""; // Clear any existing error message
          this.$emit("passcodeSuccess"); // Emit event to show dashboard
        } else {
          this.clearPasscode();
          this.errorMessage =
            response.data.message || "Invalid passcode. Please try again.";
        }
      } catch (error) {
        this.clearPasscode(); // Clear the entered passcode
        this.errorMessage =
          error.response?.data?.message ||
          "Invalid passcode. Please try again.";
      }
    },
    clearPasscode() {
      // Clear all passcode inputs
      this.passcode = ["", "", "", ""];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.finish-setup-outer-container {
  background-color: #F8F2DC;
}
.finish-setup-padding {
  padding: 30px;
}
.finish-setup-inner-container {
  border-radius: 10px !important;
  padding: 30px;
  display: grid;
  align-items: center;
}
.finish-setup-container {
  min-height: 330px;
  border-radius: 10px;
  background: var(
    --intro-gradient-1,
    linear-gradient(0deg, #fff8e2 0.71%, #fff1c3 56.33%, #ffeaa6 99.97%)
  );
}
.passcode-setup {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f1e6b9; /* Light beige background */
}

.text-danger {
  text-align: center;
  margin-bottom: 4px;
  font-family: Arial;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.title {
  color: #383838;
  font-family: Arial;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.forgot-passcode-container {
  text-align: center;
}
/* Extra Large Screens (1441px - 1600px) */
@media (min-width: 1441px) and (max-width: 1600px) {
  .forgot-passcode-container {
    margin-left: 452px;
    margin-top: -50px;
  }
}

/* Ultra Large Screens (1601px - 1920px) */
@media (min-width: 1601px) and (max-width: 1920px) {
  .forgot-passcode-container {
    margin-left: 491px;
    margin-top: -50px;
  }
}

/* 4K and Above (1921px and up) */
@media (min-width: 1921px) {
  .forgot-passcode-container {
    margin-left: 490px;
    margin-top: -50px;
  }
}

/* Tablets (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .forgot-passcode-container {
    margin-top: -50px;
    margin-left: 402px;
  }
}

/* Small Laptops (1200px - 1440px) */
@media (min-width: 1200px) and (max-width: 1440px) {
  .forgot-passcode-container {
    margin-top: -50px;
    margin-left: 430px;
  }
}

/* Medium Laptops (1025px - 1200px) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .forgot-passcode-container {
    margin-left: 448px;
    margin-top: -50px;
  }
}

/* Small Devices (576px - 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  .forgot-passcode-container {
    margin-left: 362px;
    margin-top: -50px;
  }
}

.forgot-passcode {
  text-align: center;
  color: #000;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  opacity: 0.5;
}

.subtitle {
  color: #383838;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* Maintain consistent line height */
}

/* Extra Large Screens (1441px - 1600px) */
@media (min-width: 1441px) and (max-width: 1600px) {
  .subtitle {
    font-size: 20px;
    width: 600px;
    margin-left: auto; /* Center alignment */
    margin-right: auto;
  }
}

/* Ultra Large Screens (1601px - 1920px) */
@media (min-width: 1601px) and (max-width: 1920px) {
  .subtitle {
    font-size: 20px;
    width: 630px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* 4K and Above (1921px and up) */
@media (min-width: 1921px) {
  .subtitle {
    font-size: 20px;
    width: 628px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Tablets (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .subtitle {
    font-size: 17px;
    width: 66%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Small Laptops (1200px - 1440px) */
@media (min-width: 1200px) and (max-width: 1440px) {
  .subtitle {
    font-size: 20px;
    width: 601px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Medium Laptops (1025px - 1200px) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .subtitle {
    font-size: 20px;
    width: 603px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Small Devices (576px - 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  .subtitle {
    font-size: 17px;
    width: 86%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Extra Small Devices (480px - 575px) */
@media (min-width: 480px) and (max-width: 575px) {
  .subtitle {
    font-size: 17px;
    width: 79%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Very Small Devices (Below 480px) */
@media (max-width: 480px) {
  .subtitle {
    font-size: 17px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}
.input-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}

.passcode-input {
  width: 64px;
  height: 75px;
  flex-shrink: 0;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 32px; /* Large font size for prominent display */
  text-align: center; /* Center the number */
  border: 2px solid #f8f2dc; /* Match border style from the image */
  background: #fbf8ec;
  outline: none; /* Remove focus outline */
  font-family: Arial, sans-serif; /* Font style */
  color: #383838; /* Dark text color */
}

.passcode-input:focus {
  border-color: #b7a684; /* Focus border color */
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.save-passcode {
  width: 310px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ede7d2;
  border: none;
  cursor: pointer;
  color: #323232;
  font-family: Arial;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
}

/* Disabled state */
.save-passcode:disabled {
  background: #5e6366;
  color: white;
  cursor: not-allowed;
  pointer-events: none; /* Ensure it is not clickable */
  opacity: 0.6; /* Make it visually inactive */
}

/* Responsive Design: Small screens (mobile phones) */
@media (max-width: 768px) {
  .title {
    font-size: 1.8rem;
  }

  .passcode-input {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }

  .save-passcode {
    width: 251px;
    height: 42px;
    font-size: 14px;
  }
}

/* Responsive Design: Medium screens (tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .title {
    font-size: 30px;
    letter-spacing: -0.6px;
  }

  .passcode-input {
    width: 55px;
    height: 55px;
  }

  .save-passcode {
    width: 251px;
    height: 42px;
    font-size: 14px;
  }
}

/* Responsive Design: Large screens (laptops and desktops) */
@media (min-width: 1025px) {
  .title {
    font-size: 45px;
  }

  .passcode-input {
    width: 64px;
    height: 75px;
  }

  .save-passcode {
    font-size: 1.1rem;
  }
}
</style>
