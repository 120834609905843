<template>
  <div>
    <!-- list of subjects -->
    <div v-if="!isLoading">
      <div v-if="showSubjectContent">
        <div
          class="row m-0 margin-top"
          :class="errorMessage ? '' : 'margin-bottom'"
        >
          <div class="setting-text">
            {{ labels.CURRENT_SUBJECT_SELECTION }}
          </div>
          <div
            class="ml-auto cursor-pointer change-selection-text"
            :class="{ disabled: isSaveDisabled === true }"
            @click="handleSubjectClick"
          >
            {{
              isSubjectEditing ? "Save selection" : "Change selection"
            }}
          </div>
        </div>
        <div class="row m-0">
          <div
            v-if="errorMessage"
            class="ml-auto error-message"
          >
            {{ errorMessage }}
          </div>
        </div>
        <div class="row">
          <!-- Dynamically render each radio button in its own col-4 -->
          <div
            v-for="subject in subjectsToDisplay"
            :key="subject.subject_id"
            class="col-4 pb-3"
          >
            <RadioButtonGroup
              :options="[
                {
                  label: subject.subject_name,
                  value: subject.subject_id,
                  color: subject.subject_base_color,
                },
              ]"
              :selected-values="selectedSubjectValue"
              :on-change="handleSelectionChangeSubject"
              :is-editing="isSubjectEditing"
              :disabled="isSaveDisabled"
            />
          </div>
        </div>
      </div>
      <!-- list of categories base on selected subject  -->
      <div v-if="showCategoryContent">
        <div
          class="row m-0 margin-top"
          :class="errorMessage ? '' : 'margin-bottom'"
        >
          <div class="setting-text">
            {{ labels.CURRENT_CATEGORY_SELECTION }}
          </div>
          <div
            class="ml-auto cursor-pointer change-selection-text"
            :class="{ disabled: isSaveDisabled }"
            @click="handleCategoryClick"
          >
            {{
              iscategoryEditing ? "Save selection" : "Change selection"
            }}
          </div>
        </div>
        <div class="row m-0">
          <div
            v-if="errorMessage"
            class="ml-auto error-message"
          >
            {{ errorMessage }}
          </div>
        </div>
        <div class="col-12 p-0">
          <div class="tabs-container">
            <ul class="nav nav-tabs">
              <!-- Render the tabs dynamically -->
              <li
                v-for="subject in subjects"
                :key="subject.subject_id"
                class="nav-item"
              >
                <a
                  class="nav-link"
                  :class="{ active: activeSubject === subject.subject_id, disabled: isTabDisabled }"
                  href="#"
                  @click.prevent="!isTabDisabled && selectTab(subject.subject_id)"
                >
                  {{ subject.subject_name }}
                </a>
              </li>
            </ul>
            <div class="tab-indicator">
              <!-- Highlight the active tab's underline -->
              <div
                class="indicator-line"
                :style="{
                  width: activeTabWidth + 'px',
                  left: activeTabOffset + 'px',
                }"
              ></div>
            </div>
            <div class="tab-content">
              <div class="row pt-4">
                <!-- Render only the categories for the active subject -->
                <div
                  v-for="category in activeSubjectCategories"
                  :key="category.category_id"
                  class="col-4 pb-3"
                >
                  <RadioButtonGroup
                    :key="category.id"
                    :options="[
                      {
                        label: category.category_name,
                        value: category.category_id,
                        color: activeSubjectColor,
                      },
                    ]"
                    :selected-values="
                      selectedCategoryValues[activeSubject] || []
                    "
                    :on-change="
                      (newSelection) =>
                        handleSelectionChangeCategory(
                          newSelection,
                          activeSubject
                        )
                    "
                    :is-editing="iscategoryEditing"
                    :disabled="isSaveDisabled"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading
      :active="isLoading"
      color="#9651F1"
      background-color="#C9C9C9"
      :height="100"
      :width="100"
    />
  </div>
</template>

<script>
import RadioButtonGroup from "../common/RadioButtonGroup.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import { updateTitle } from "@/utils/utils";
import { LABELS } from "@/constants/labels";

export default {
  name: "SettingsComponent",
  components: { RadioButtonGroup, Loading },
  data() {
    return {
      subjects: [], // Holds subject data from API
      categories: [], // Categories of the subject
      // listOfCategorys: [],
      activeSubject: null, // Currently active tab (subject ID)
      activeSubjectColor: "", // Holds the active subject's color
      selectedSubjectValue: [], // Selected subject values
      parentSelectedSubjectValue: [], // already selected subject while onboarding
      listOfSubjects: [], //list of all subject
      selectedCategoryValues: {}, // Selected category values
      parentSelectetcategoryValue: [],
      isSubjectEditing: false,
      iscategoryEditing: false,
      errorMessage: "",
      email: localStorage.getItem("email"),
      activeTabWidth: 0, // Width of the active tab
      activeTabOffset: 0, // Left offset of the active tab
      showSubjectContent: true,
      showCategoryContent: true,
      isLoading: false,
      isLoadingCategory: false,
      saveSubjectNext: false,
      isTabDisabled: false,
      labels: LABELS,
    };
  },
  computed: {
    // Map Vuex getter to access subjects
    ...mapGetters(["getSubjects"]),
    getAllSubjects() {
      return this.getSubjects; // Access subjects from Vuex
    },
    subjectsToDisplay() {
      return this.isSubjectEditing ? this.getAllSubjects : this.subjects;
    },
    activeSubjectCategories() {
      const activeSubjectData = this.subjects.find(
        (subject) => subject.subject_id === this.activeSubject
      );
      return activeSubjectData ? activeSubjectData.categories : [];
    },
    isSaveDisabled() {
      const result = this.isLoadingCategory || !!this.errorMessage;
      return result;
    },
    categoryToDisplayUnderTheSubject() {
      return this.iscategoryEditing ? this.listOfCategorys : this.categories;
    },
  },
  watch: {
    activeSubject() {
      // Reload the state for the new subject
      this.loadState();
    },
  },
  mounted() {
     updateTitle("Settings");
    this.fetchSelectSubjectCategoryData();
    this.loadState();
    window.addEventListener("resize", this.updateIndicatorPosition);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIndicatorPosition);
  },
  methods: {
    handleSubjectClick() {
      if (!this.isSaveDisabled) {
        this.toggleChangeSubject();
      }
    },
    handleCategoryClick() {
      if (!this.isSaveDisabled) {
        this.toggleChangeCategory();
      }
    },
    updateIndicatorPosition() {
      // Get the active tab element
      this.$nextTick(() => {
        const activeTabElement = this.$el.querySelector(".nav-link.active");
        if (activeTabElement) {
          this.activeTabWidth = activeTabElement.offsetWidth;
          this.activeTabOffset = activeTabElement.offsetLeft;
        }
      });
    },
    handleSelectionChangeSubject(newSelection) {
      // Ensure unselected values are removed
      this.selectedSubjectValue = this.selectedSubjectValue.filter((value) =>
        newSelection.includes(value)
      );
      this.selectedSubjectValue = [...newSelection];
      // Add only newly checked values

      if (newSelection.length > 3) {
        this.errorMessage = "Select minimum 3 subjects.";
      } else if (newSelection.length === 0) {
        this.errorMessage = "Select minimum 3 subjects.";
      } else {
        this.errorMessage = "";
        newSelection.forEach((value) => {
          if (!this.selectedSubjectValue.includes(value)) {
            if (this.selectedSubjectValue) {
              this.selectedSubjectValue.push(value);
            }
          }
        });
      }
    },
    handleSelectionChangeCategory(newSelection, subjectId) {
      // Update selected categories for the subjectId
      this.selectedCategoryValues[subjectId] = [...newSelection];

      // Validate the new selection size
      const newSelectionSize = newSelection.length;

      // Validate: Cannot select more than 3 categories
      if (newSelectionSize > 3) {
        this.errorMessage = `You can select a maximum of 3 categories for ${this.getSubjectNameById(
          subjectId
        )}.`;
        this.isTabDisabled = true; // Disable tabs
        return; // Prevent further updates if more than 3 categories are selected
      }

      // Validate: Cannot deselect all categories
      if (newSelectionSize === 0) {
        this.errorMessage = `Please select a minimum of 1 and maximum of 3 categories for ${this.getSubjectNameById(
          subjectId
        )}.`;
        this.isTabDisabled = true; // Disable tabs
        return; // Prevent further updates if no categories are selected
      }

      // Clear error if valid
      this.errorMessage = "";
      this.isTabDisabled = false; // Enable tabs

      // Save updated state
      this.saveState();
    },

    // Helper function to get subject name by ID
    getSubjectNameById(subjectId) {
      const subject = this.subjects.find(
        (subject) => subject.subject_id === subjectId
      );
      return subject ? subject.subject_name : `Subject ${subjectId}`;
    },

    toggleChangeSubject() {
      if (!this.isSubjectEditing) {
        this.isSubjectEditing = true;
        // once user  click on change seletion button aleady seleted  are getting bold.
        this.selectedSubjectValue = [...this.parentSelectedSubjectValue];
        this.showSubjectContent = true;
        this.showCategoryContent = false;
      } else {
        // Validate the number of selected subjects before proceeding
        if (this.selectedSubjectValue.length < 3) {
          this.errorMessage = "Select minimum 3 subjects.";
          return; // Stop further execution if the condition is not met
        }

        // Clear the error message and proceed
        this.errorMessage = "";

        // Exit editing: show category content and fetch category
        this.isSubjectEditing = false;
        this.showSubjectContent = false;
        this.showCategoryContent = true;
        this.iscategoryEditing = true;

        // Make the API call and allow moving to the next step
        this.fetchCategory();
        this.saveSubjectNext = true;
      }
    },

    toggleChangeCategory() {
      if (!this.iscategoryEditing) {
        this.iscategoryEditing = true;
        this.showSubjectContent = false;
        this.showCategoryContent = true;
        this.selectedSubjectValue = this.parentSelectedSubjectValue;
        if (!this.saveSubjectNext) {
          this.fetchCategory();
        }
        // once user  click on change seletion button aleady seleted category are getting bold.
        this.matchingCategory();
        // Save to localStorage for persistence
        this.saveState();
        // this.selectedCategoryValues = [...this.parentSelectedCategoryValue];
      } else {
        // Check for missing or invalid category selections
        const invalidSubjects = Object.entries(
          this.selectedCategoryValues
        ).filter(([categories]) => {
          const count = categories.length;
          return count < 1 || count > 3; // Invalid if less than 1 or more than 3 categories selected
        });

        if (invalidSubjects.length === 0) {
          // All subjects have valid category selections
          this.iscategoryEditing = false;
          this.errorMessage = "";
          // Proceed with saving or next step
          this.saveState();
          this.saveSelection();
        } else {
          // Map invalid subject IDs to their names
          const invalidSubjectNames = invalidSubjects
            .map(([subjectId]) => {
              const subject = this.subjects.find(
                (subject) => subject.subject_id === parseInt(subjectId)
              );
              return subject ? subject.subject_name : subjectId; // Use name if available, fallback to ID
            })
            .filter(Boolean); // Remove undefined values if any

          // Set the error message
          this.errorMessage = ` Please select a minimum of 1 and maximum of 3 categories for  ${invalidSubjectNames}`
        }
      }
    },
    matchingCategory() {
        // once user  click on change seletion button aleady seleted category are getting bold.
        // Retrieve stored selections from localStorage
        const storedSelections =
          JSON.parse(localStorage.getItem("iniSubjectAndCategory")) || [];
        const storedSelectionsMap = {};
        storedSelections.forEach((storedSubject) => {
          storedSelectionsMap[storedSubject.subject_id] =
            storedSubject.categories.map((category) => category.category_id);
        });

        // Initialize selectedCategoryValues with existing categories
        this.subjects.forEach((subject) => {
          const subjectId = subject.subject_id;

          // Initialize selectedCategoryValues for each subject
          this.$set(this.selectedCategoryValues, subjectId, []);

          if (storedSelectionsMap[subjectId]) {
            const storedCategoryIds = storedSelectionsMap[subjectId];
            subject.categories.forEach((category) => {
              if (storedCategoryIds.includes(category.category_id)) {
                // Mark preselected categories
                this.selectedCategoryValues[subjectId].push(
                  category.category_id
                );
              }
            });
          }
        });
    },
    async fetchSelectSubjectCategoryData() {
      this.selectedSubjectValue = [];
      this.isLoading = true;
      let dataTosend = {};
      dataTosend.user_email = this.email;
      const encodedEmail = encodeURIComponent(this.email);
      axios
        .get(
          `${process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN}${process.env.VUE_APP_FLUTE_N_FEATHER_USER_SETTING}?user_email=${encodedEmail}`
        )
        .then((response) => {
          this.isLoading = false;
          this.subjects = response.data;
          this.selectedCategoryValues = {};
          localStorage.setItem(
            "iniSubjectAndCategory",
            JSON.stringify(response.data)
          );
          if (this.subjects.length > 0) {
            this.parentSelectedSubjectValue = this.subjects.map(
              (subject) => subject.subject_id
            );
            this.listOfSubjects = this.getAllSubjects.map(
              (subject) => subject.subject_id
            );
            this.updateIndicatorPosition();
            this.activeSubject = this.subjects[0].subject_id;
            this.categories = this.subjects[0].categories;
            this.activeSubjectColor = this.subjects[0].subject_base_color || "";
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    async fetchCategory() {
      try {
        this.isLoadingCategory = true;
        this.isLoading = true;
        this.errorMessage = "";
        const dataToSend = {
          user_email: localStorage.getItem("email"),
          subject_ids:
            this.selectedSubjectValue.length > 0
              ? this.selectedSubjectValue
              : this.parentSelectedSubjectValue,
        };
        const response = await axios.post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env
              .VUE_APP_FLUTE_N_FEATHER_SUBJECT_CATEGORIES_DETAILS_URL_PATH,
          dataToSend
        );

        if (response && response.data) {
          this.isLoadingCategory = false;
          this.isLoading = false;
          this.subjects = response.data.subjects;
          if (this.subjects.length > 0) {
          this.updateIndicatorPosition();
          this.activeSubject = this.subjects[0].subject_id;
            this.categories = this.subjects[0].categories;
            this.activeSubjectColor = this.subjects[0].subject_base_color || "";
            this.matchingCategory();
          }
        } else {
          this.isLoadingCategory = false;
          this.isLoading = false;
          this.errorMessage =
            response?.data?.message || "Categories not found.";
        }
      } catch (error) {
        this.isLoadingCategory = false;
        this.isLoading = false;
        this.errorMessage =
          error.response?.data?.message ||
          "Something went wrong. Please try again.";
      }
    },
    saveSelection() {
      this.isLoading = true;
      let dataTosend = {};
      dataTosend.user_email = this.email;
      const selectedCategory = JSON.parse(
        localStorage.getItem("selectedCategory")
      );
      const formattedCategories = selectedCategory.map((subject) => ({
        subject_id: subject.subject_id,
        category_ids: subject.categories.map(
          (category) => category.category_id
        ),
      }));
      dataTosend.subjects = formattedCategories;
      axios
        .post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_UPDATE_SETTING,
          dataTosend
        )
        .then(() => {
          this.isLoading = false;
          this.fetchSelectSubjectCategoryData();
          this.showSubjectContent = true;
          this.showCategoryContent = true;
          this.saveSubjectNext = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    saveState() {
      localStorage.setItem(
        "selectedSubjects",
        JSON.stringify(this.selectedSubjectValue)
      );
      const subjectCategorySelections = Object.keys(
        this.selectedCategoryValues
      ).map((subjectId) => {
        const categories = Array.isArray(this.selectedCategoryValues[subjectId])
          ? this.selectedCategoryValues[subjectId]
          : [];
        return {
          subject_id: subjectId,
          categories: categories.map((categoryId) => ({
            category_id: categoryId,
          })),
        };
      });
      localStorage.setItem(
        "selectedCategory",
        JSON.stringify(subjectCategorySelections)
      );
    },
    loadState() {
      // Load the state from localStorage or another storage mechanism
      const savedState = JSON.parse(
        localStorage.getItem("selectedCategoryValues")
      );
      if (savedState) {
        this.selectedCategoryValues = savedState;
      }
    },

    selectTab(subjectId) {
     // Validate the current active tab before switching
    const currentTabCategories = this.selectedCategoryValues[this.activeSubject] || [];

    // Check if no category is selected in the current active tab
    if(this.isSubjectEditing || this.iscategoryEditing === true) {
    if (currentTabCategories.length === 0) {
        this.errorMessage = `Please select a minimum of 1 and maximum of 3 categories for ${this.getSubjectNameById(
            this.activeSubject
        )}.`;
        return; // Prevent tab switch
    }
    }
      this.activeSubject = subjectId;
      this.updateIndicatorPosition();
      // Find the selected subject based on subjectId
      const selectedSubject = this.subjects.find(
        (subject) => subject.subject_id === subjectId
      );

      // Set categories and subject color based on the selected subject
      this.categories = selectedSubject ? selectedSubject.categories : [];
      this.activeSubjectColor = selectedSubject?.subject_base_color || "";

      // Trigger reactivity for selected values to update the UI
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}
.tabs-container {
  position: relative;
}
.nav-tabs {
  display: flex;
  justify-content: flex-start;
  gap: 5rem;
  padding: 0;
  list-style: none;
  border-bottom: none;
}

.nav-item {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
}

.nav-link {
  text-decoration: none;
  border: none;
  padding: 0px !important;
  color: $text-color;
}

.nav-link.active {
  color: $text-color;
  font-weight: 700;
}

.nav-link:hover {
  color: #333 !important;
}
.tab-indicator {
  position: relative;
  height: 2px;
  background: var(--copy-color, #8b8a8a);
  opacity: 0.5;
  width: 100%;
}

.indicator-line {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--copy-color, #323232);
  transition: all 0.3s ease;
}
.setting-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.change-selection-text {
  color: $text-color;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 2rem;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}
</style>
