<template>
  <div>
    <div class="margin-top help-container">
      <div class="helpContent">
        Need help or looking to unsubscribe from our service? Feel free to email us with your questions at <u class="fw-600"> hello@flutenfeather.com</u>. We try to get back to you as soon as possible, but please give 24 hours.
      </div>
    </div>
  </div>
</template>

<script>
import { updateTitle } from "@/utils/utils";
export default {
mounted() {
   updateTitle("Help");
}
}
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.helpContent {
  color: $text-color;
  font-family: Arial;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  border-radius: 10px;
background: $dashboard-content-bg-color;
padding: 30px;
}
</style>
