<template>
  <b-button
    :class="['btn', btnClass]"
    @click="handleClick"
  >
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    btnClass: {
      type: String,
      default: "", // Default CSS class for the button
    },
  },
  methods: {
    handleClick() {
      // Emit a click event
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.btn {
  width: 100%;
  font-size: 18px;
  line-height: normal;
  border-radius: 10px;
  padding: 21px, 174px;
  background: $btn-color;
  border: none;
  height: 60px;
  margin-top: 20px;
  color: $text-color; /* Text color when disabled */
  font-style: normal;
  font-weight: 400;
}

.btn:hover {
  color: #333; /* Ensure text stays black on hover */
}
.btn:disabled {
  background: $btn-color;
  color: $black-color; /* Text color when disabled */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  opacity: 0.4;
}

.btn:disabled:hover {
  background: $btn-color;
  color: $black-color; /* Ensure text color stays black on hover */
}
/* Add scoped CSS styles for your button */
</style>
