var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isLoading)?_c('div',[(_vm.showSubjectContent)?_c('div',[_c('div',{staticClass:"row m-0 margin-top",class:_vm.errorMessage ? '' : 'margin-bottom'},[_c('div',{staticClass:"setting-text"},[_vm._v(" "+_vm._s(_vm.labels.CURRENT_SUBJECT_SELECTION)+" ")]),_c('div',{staticClass:"ml-auto cursor-pointer change-selection-text",class:{ disabled: _vm.isSaveDisabled === true },on:{"click":_vm.handleSubjectClick}},[_vm._v(" "+_vm._s(_vm.isSubjectEditing ? "Save selection" : "Change selection")+" ")])]),_c('div',{staticClass:"row m-0"},[(_vm.errorMessage)?_c('div',{staticClass:"ml-auto error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]),_c('div',{staticClass:"row"},_vm._l((_vm.subjectsToDisplay),function(subject){return _c('div',{key:subject.subject_id,staticClass:"col-4 pb-3"},[_c('RadioButtonGroup',{attrs:{"options":[
              {
                label: subject.subject_name,
                value: subject.subject_id,
                color: subject.subject_base_color,
              },
            ],"selected-values":_vm.selectedSubjectValue,"on-change":_vm.handleSelectionChangeSubject,"is-editing":_vm.isSubjectEditing,"disabled":_vm.isSaveDisabled}})],1)}),0)]):_vm._e(),(_vm.showCategoryContent)?_c('div',[_c('div',{staticClass:"row m-0 margin-top",class:_vm.errorMessage ? '' : 'margin-bottom'},[_c('div',{staticClass:"setting-text"},[_vm._v(" "+_vm._s(_vm.labels.CURRENT_CATEGORY_SELECTION)+" ")]),_c('div',{staticClass:"ml-auto cursor-pointer change-selection-text",class:{ disabled: _vm.isSaveDisabled },on:{"click":_vm.handleCategoryClick}},[_vm._v(" "+_vm._s(_vm.iscategoryEditing ? "Save selection" : "Change selection")+" ")])]),_c('div',{staticClass:"row m-0"},[(_vm.errorMessage)?_c('div',{staticClass:"ml-auto error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]),_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"tabs-container"},[_c('ul',{staticClass:"nav nav-tabs"},_vm._l((_vm.subjects),function(subject){return _c('li',{key:subject.subject_id,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{ active: _vm.activeSubject === subject.subject_id, disabled: _vm.isTabDisabled },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();!_vm.isTabDisabled && _vm.selectTab(subject.subject_id)}}},[_vm._v(" "+_vm._s(subject.subject_name)+" ")])])}),0),_c('div',{staticClass:"tab-indicator"},[_c('div',{staticClass:"indicator-line",style:({
                width: _vm.activeTabWidth + 'px',
                left: _vm.activeTabOffset + 'px',
              })})]),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"row pt-4"},_vm._l((_vm.activeSubjectCategories),function(category){return _c('div',{key:category.category_id,staticClass:"col-4 pb-3"},[_c('RadioButtonGroup',{key:category.id,attrs:{"options":[
                    {
                      label: category.category_name,
                      value: category.category_id,
                      color: _vm.activeSubjectColor,
                    },
                  ],"selected-values":_vm.selectedCategoryValues[_vm.activeSubject] || [],"on-change":(newSelection) =>
                      _vm.handleSelectionChangeCategory(
                        newSelection,
                        _vm.activeSubject
                      ),"is-editing":_vm.iscategoryEditing,"disabled":_vm.isSaveDisabled}})],1)}),0)])])])]):_vm._e()]):_vm._e(),_c('loading',{attrs:{"active":_vm.isLoading,"color":"#9651F1","background-color":"#C9C9C9","height":100,"width":100}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }